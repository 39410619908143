import {
  Application,
  ClientAppConfig,
  Order,
  Offer,
} from '@ads-bread/shared/bread/codecs';
import { CheckoutXPropOptions } from '@ads-bread/shared/bread/util';
import { booleanFromString } from '../boolean';
import { adobeClient } from './adobe';
import { decibelClient } from './decibel';
import { noopClient } from './noop';

export type ObjectValue = Record<string, boolean | string | number | undefined>;
export type Values = ObjectValue | boolean | string | number | undefined;
export type Keys = number | string;
export type EventProperties = Record<Keys, Values>;

export type CheckoutDetails = Pick<
  CheckoutXPropOptions,
  | 'allowCheckout'
  | 'analytics'
  | 'order'
  | 'isDefaultOrder'
  | 'merchantID'
  | 'programID'
  | 'locationID'
  | 'promoOffers'
  | 'isEmbedded'
  | 'merchantPaymentProducts'
> & {
  tenantID: string;
  isVirtualCard: boolean;
  isInStore: boolean;
  isCarts: boolean;
  isMerchantTapToPayCapable: boolean;
  isMerchantKeyEntryCapable: boolean;
  isDownPaymentEnabled: boolean;
  merchantName: string;
  programName: string;
};

export enum AnalyticsEvent {
  ModalOpened = 'modal_opened',
  PageLoaded = 'page_loaded',
  ButtonClicked = 'button_clicked',
  FormSubmitError = 'form_submit_error',
  FormInlineError = 'form_inline_error',
  EstimatedSpendInlineError = 'estimated_spend_inline_error',
  AddCardError = 'add_card_error',
  ShowCardDetails = 'show_card_details',
  AddToAppleWallet = 'add_to_apple_wallet',
  AddToGooglePay = 'add_to_googlepay',
  AddToAppleWalletError = 'add_to_apple_wallet_error',
  AddToGooglePayError = 'add_to_googlepay_error',
  AddToAppleWalletSuccess = 'successfully_added_to_apple_wallet',
  AddToGoogleWalletSuccess = 'successfully_added_to_google_wallet',
  CheckoutCompleted = 'checkout_completed',
  AutoPayEnrollmentSuccess = 'auto_pay_enrollment_success',
}

export enum PageNames {
  Intro = 'Intro Page',
  IntroInformation = 'In Store Pre Intro Page',
  IntroEmail = 'Intro Email Page',
  IntroSideBySide = 'Intro Side By Side',
  IntroSingleStackDualSideBySideEmailAdded = 'Intro Single Stack Dual Side By Side Email Added Page',
  IntroSingleStackDualSideBySide = 'Intro Single Stack Dual Side By Side Page',
  LearnMoreInstallmentOverlay = 'Learn More Installment Overlay',
  LearnMoreSplitPayOverlay = 'Learn More Splitpay Overlay',
  Email = 'Email Page',
  Mobile = 'Mobile Page',
  Verification = 'Verification Code Page',
  BillingAddress = 'Billing Address Page',
  EditBillingAddressOverlay = 'Edit Billing Address Overlay',
  ReviewApplication = 'Review Application Page',
  Eligibility = 'Eligibility Page',
  NeedsFullIIN = 'Needs Full IIN Page',
  LoginEligibility = 'Login Eligibility Page',
  ConfirmEmail = 'Confirm Email Page',
  AddCard = ' Add Card Page',
  Terms = 'Terms Page',
  Employment = 'Employment Page',
  Review = 'Review Page',
  LoanAgreement = 'Loan Agreement Page',
  Payment = 'Add Payment Page',
  Confirmation = 'Confirmation Page',
  EstimatedSpendCalculator = 'Calculator Page',
  FAQ = 'FAQ Page',
  IDVerificationInstructions = 'ID Verification Instructions Page',
  IDVerificationNeedsAction = 'ID Verification Needs Action Page',
  IDVerificationUploadFront = 'ID Verification Upload Front Page',
  IDVerificationUploadBack = 'ID Verification Upload Back Page',
  IDVerificationReview = 'ID Verification Review Page',
  IDVerificationUploadingPhotos = 'Uploading Photos Page',
  IDVerificationAlloy = 'ID Verification with Alloy Page',
  IDVerificationAlloyNeedsAction = 'ID Verification Needs Action with Alloy Page',
  IDVerificationNeedsActionWarningError = 'Error - ID Verification Needs Action Warning Page',
  IDVerificationFailure = 'Error - ID Verification Failure Page',
  IDVerificationMaxAttempts = 'Error - ID Verification Max Attempts Exceeded Page',
  CreditFreezeError = 'Error - Credit Freeze Page',
  CreditDenialError = 'Error - Credit Denial Page',
  PreviousDenialError = 'Error - Previous Denial Page',
  BuyerStatusIneligible = 'Error - Buyer Status Ineligible Page',
  BuyerAgeIneligibleError = 'Error - Buyer Age Ineligible Page',
  LocationIneligibleError = 'Error - Location Ineligible Page',
  KYCDenialError = 'Error - KYC Denial Page',
  OFACError = 'Error - OFAC Page',
  AgeAlabamaMilitaryError = 'Error - Age Alabama Military Page',
  BuyerHashError = 'Error- Buyer Hash Failed Page',
  BuyerSkippedInstallmentsError = 'Error - Buyer Skipped Installments Page',
  NeedsActionWarningError = 'Error - Needs Action Warning Page',
  MaxCardIneligibleError = 'Error - Max Card Ineligible Page',
  LoanStatusIneligibleError = 'Error - Loan Status Ineligible Page',
  OutstandingLoanIneligibleError = 'Error - Outstanding Loan Ineligible Page',
  POBoxAddressIneligibleError = 'Error - PO Box Address Ineligible Page',
  UnknownError = 'Error - Unknown Error Page',
  VerifiedEmailError = 'Error - Verified Email Page',
  FraudAlertDenialError = 'Error - Fraud Alert Denial Page',
  FraudDenialError = 'Error - Fraud Denial Page',
  SanctionDenialError = 'Error - Sanctions Denial Page',
  VirtualCardFailedError = 'Error - Virtual Card Failed Page',
  VirtualCardInvalidMerchantConfigError = 'Error - Invalid Merchant Config Page',
  VirtualCardUnsupportedDeviceError = 'Error - Unsupported Device Page',
  VirtualCardFraudStopError = 'Error - Fraud Stop Failure Page',
  CapacityRecheckError = 'Error - Capacity Change',
  LocationDisabled = 'Error - Location Disabled',
  NotFoundError = 'Error - 404 Error Page',
}

export enum ButtonClickedEvents {
  GetStarted = 'Get started',
}

export type Analytics = {
  track(event: AnalyticsEvent, properties?: EventProperties): void;

  setApplication(data: Application): void;

  setSelectedOffer(offer: Offer): void;

  setOrder(order: Order, isDefaultOrder?: boolean): void;

  identify(buyerId: string): void;

  setCheckoutDetails(details: CheckoutDetails): void;

  trackLogout(): void;

  initialize(config: ClientAppConfig): void;

  setAdditionalContext(properties: EventProperties): void;
};

let initialized = false;

const analyticsClients: Partial<Analytics>[] =
  process.env.NODE_ENV === 'production'
    ? [adobeClient, decibelClient]
    : [noopClient];

const logCalls = booleanFromString(process.env.NEXT_PUBLIC_LOG_ANALYTICS_CALLS);

function logAnalyticCall(method: string, args?: unknown[]): void {
  const event: Record<string, unknown> = { call: method };
  if (args?.length) {
    event.args = args;
  }
  // eslint-disable-next-line no-console
  logCalls && console.debug(event);
}

type ProxyCall = {
  name: string;
  args: never[];
};

const queue: ProxyCall[] = [];

declare global {
  interface ProxyConstructor {
    // eslint-disable-next-line @typescript-eslint/ban-types
    new <TSource extends object, TTarget extends object>(
      target: TSource,
      handler: ProxyHandler<TSource>
    ): TTarget;
  }
}

const proxy = new Proxy<Partial<Analytics>[], Analytics>(analyticsClients, {
  get(
    target: Analytics[],
    methodName: keyof Analytics,
    receiver: unknown
  ): unknown {
    if (!initialized && methodName !== 'initialize') {
      return (...args: never[]) => {
        queue.push({ name: methodName, args: [...args] });
      };
    }
    const originals = target.map((client) => {
      const original = Reflect.get(client, methodName, receiver);
      if (typeof original === 'function') {
        return (...args: [never, never]) => {
          logAnalyticCall(methodName, args);
          return original(...args);
        };
      }
      logAnalyticCall(methodName);
      return original;
    });
    const wrapped = (...args: [never, never]) => {
      originals.forEach((o) => {
        if (typeof o === 'function') {
          o(...args);
        }
      });
    };
    return wrapped;
  },
});

// Our proxy can call any method because it verifies for the methods' existence
// on the client at runtime
// eslint-disable-next-line @typescript-eslint/ban-types
function hasKey<T extends object>(k: keyof any): k is keyof T {
  return !!k;
}
// After initialization call any enqueued methods
function setInitialized(): void {
  initialized = true;
  while (queue.length > 0) {
    const item = queue.shift();
    if (item) {
      const { name, args } = item;
      hasKey(name) &&
        typeof proxy[name] === 'function' &&
        // eslint-disable-next-line prefer-spread
        (proxy[name] as (...argz: any[]) => void).apply(null, args);
    }
  }
}

export function initialize(config: ClientAppConfig): void {
  proxy.initialize(config);
  setInitialized();
}

export function track(
  event: AnalyticsEvent,
  properties?: EventProperties
): void {
  proxy.track(event, properties);
}

export function setApplication(application: Application): void {
  proxy.setApplication(application);
  proxy.setOrder(application.order);
}

export function setSelectedOffer(offer: Offer): void {
  proxy.setSelectedOffer(offer);
}

export function identify(buyerId: string): void {
  proxy.identify(buyerId);
}

export function trackLogout(): void {
  proxy.trackLogout();
}

export function setCheckoutDetails(details: CheckoutDetails): void {
  proxy.setCheckoutDetails(details);
  proxy.setOrder(details.order, details.isDefaultOrder);
}

export function setAdditionalContext(properties: EventProperties): void {
  proxy.setAdditionalContext(properties);
}
