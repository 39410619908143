import { createContext, useEffect, useRef } from 'react';
import { ActorRefFrom, fromPromise, assign } from 'xstate';
import { useActorRef } from '@xstate/react';
import {
  Application,
  APPLICATION_STATUS,
  DisclosuresAttribute,
  Order,
  PaymentTypes,
} from '@ads-bread/shared/bread/codecs';
import {
  APPLICATION_STATUS_CODES,
  ID_VERIFICATION_STATUS_CODES,
  NAMESPACE_MAP,
  usd,
  validateOfferProductTypes,
} from '@ads-bread/shared/bread/util';
import isEqual from 'lodash.isequal';
import { FCWithChildren } from '../../lib/types';
import { needsManualReview, hasFailed } from '../../lib/fraudResponses';
import { useFetch } from '../../lib/hooks/apiFetch';
import { toErrorResponse, toResultResponse } from '../../lib/handlers/base';
import { useNamespace } from '../../lib/hooks/useNamespace';
import {
  isApproved,
  needsFullIIN,
  needsIDVerification,
  APPLICATION_STATUS_CODE_ERROR_MAP,
} from '../../lib/applicationStatus';
import {
  setApplication as setApplicationAnalytics,
  setSelectedOffer as setSelectedOfferAnalytics,
} from '../../lib/analytics';
import { logger } from '../../lib/logger';
import { groupOffersByType } from '../../lib/offers';
import { getMaxCapacities } from '../../lib/capacity';
import { getApplication } from '../../lib/handlers/get-application';
import { createApplication } from '../../lib/handlers/create-application';
import { getLatestApplication } from '../../lib/handlers/get-latest-application';
import { getPaymentMethod } from '../../lib/handlers/get-payment-method';
import { pollVirtualCardIssuance } from '../../lib/handlers/virtual-card-issuance';
import { applicationCheckout } from '../../lib/handlers/application-checkout';
import { applicationPrepareCheckout } from '../../lib/handlers/application-prepare-checkout';
import {
  evaluateIDVerificationImages,
  uploadIDVerificationImage,
} from '../../lib/handlers/id-verification';
import { useAppConfig } from '../AppConfigContext';
import { useAppData } from '../AppDataContext';
import { useBuyerMachine } from '../BuyerMachineContext';
import { useFeatureFlags } from '../FeatureFlagsContext';
import { useLocale } from '../IntlContext';
import {
  useOrder,
  useLocationID,
  useMerchantID,
  useCartID,
  useMerchantOrigin,
  useProgramValues,
  useMerchantPaymentProducts,
  useMerchantDetails,
  useSDKCallbacks,
  useVirtualCard,
} from '../XPropsContext';
import { useAuthentication } from '../AuthenticationMachineContext';
import { ApplicationContext, applicationMachine } from './applicationMachine';
import {
  assignApplication,
  assignApplicationWithAppendedStatusCodes,
  assignIDVerificationBackImageData,
  assignIDVerificationFrontImageData,
  assignPaymentAgreementDocument,
  assignSelectedOffer,
} from './assigns';
import {
  filterApplicationCapacity,
  getOriginFromStatus,
  isIneligibleAddress,
  isUnexpiredInStoreApplication,
  isValidApplication,
  matchesPreviouslyApproved,
  stringifyShippingDescription,
} from './utils';
import {
  ApplicationCheckoutResult,
  ApplicationPrepareCheckoutResult,
  CreateApplicationResult,
  FetchLatestApplicationResult,
  ReCreateApplicationResult,
  ReCreateInStoreContingentApplicationResult,
} from './types/actors';

export type ApplicationMachineActorRef = ActorRefFrom<
  typeof applicationMachine
>;

export const ApplicationMachineContext =
  createContext<ApplicationMachineActorRef | null>(null);

export type ApplicationProviderProps = {
  context?: Partial<ApplicationContext>;
};

const ZERO_UUID = '00000000-0000-0000-0000-000000000000';

export const ApplicationMachineProvider: FCWithChildren<ApplicationProviderProps> =
  ({ children, context = {} }) => {
    const { state: authState } = useAuthentication();
    const { state: buyerState, buyer: contextBuyer } = useBuyerMachine();
    const {
      setPaymentMethodID,
      setPaymentMethods,
      setEstimatedSpend,
      setVirtualCardIssuance,
    } = useAppData();
    const { order } = useOrder();
    const { enableIDVerification } = useAppConfig();
    const apiFetch = useFetch();
    const locationID = useLocationID();
    const { merchantID } = useMerchantID();
    const cartID = useCartID();
    const { merchantOrigin } = useMerchantOrigin();
    const program = useProgramValues();
    const { locale } = useLocale();
    const { merchantPaymentProducts } = useMerchantPaymentProducts();
    const { canViewCapacity } = useMerchantDetails();
    const { onApproved, onCheckout } = useSDKCallbacks();
    const { isVirtualCard } = useVirtualCard();
    const lastOrderRef = useRef<Order>();
    const namespace = useNamespace();
    const { enableAlloyJourney } = useFeatureFlags();

    const initialContext: ApplicationContext = {
      application: context.application || null,
      selectedOffer: context.selectedOffer || null,
      paymentAgreementDocument: context.paymentAgreementDocument || null,
      idVerification: {
        frontImageData: context.idVerification?.frontImageData || null,
        backImageData: context.idVerification?.backImageData || null,
        isIDVerified: context.idVerification?.isIDVerified || false,
      },
    };

    const applicationService = useActorRef(
      applicationMachine.provide({
        guards: {
          isInstallmentOfferSelected: (_, params) => {
            return (
              params.selectedOffer.paymentProduct.type ===
              PaymentTypes.INSTALLMENTS
            );
          },
          isSplitPayOfferSelected: (_, params) => {
            return (
              params.selectedOffer.paymentProduct.type === PaymentTypes.SPLITPAY
            );
          },
          isUnexpiredInStoreApplication: (_, params) => {
            const app = params.applicationRes.result;

            if (!app) {
              return false;
            }

            return isUnexpiredInStoreApplication(namespace, app);
          },
          isIneligibleAddress: (_, params) => {
            const app = params.applicationRes.result;
            if (!app) {
              return false;
            }
            return isIneligibleAddress(app);
          },
          isInvalidApplication: (_, params) => {
            const app = params.applicationRes.result;
            if (!app) {
              return true;
            }
            return !isValidApplication(app, merchantPaymentProducts);
          },
          isCompleteMismatchedBuyer: () => {
            return (
              (authState.matches({
                authenticated: { anonymous: 'mismatchedBuyerPII' },
              }) ||
                authState.matches({
                  authenticated: { complete: 'mismatchedBuyerPII' },
                })) &&
              buyerState.matches({ ready: 'complete' })
            );
          },
          needsFullIIN: (_, params) => {
            const app = params.applicationRes.result;
            if (!app) {
              return false;
            }
            return needsFullIIN(app);
          },
          isValidApplicationAndMatchesPreviouslyApprovedOrder: (_, params) => {
            const app = params.applicationRes.result;
            if (!app) {
              return false;
            }
            return (
              matchesPreviouslyApproved(app, order) &&
              isValidApplication(app, merchantPaymentProducts)
            );
          },
          isBuyerReadyAndComplete: () => {
            return buyerState.matches({ ready: 'complete' });
          },
          isApproved: (_, params) => {
            const application = params.applicationRes.result;
            if (!application) {
              return false;
            }
            return isApproved(application);
          },
          isApprovedContingentInStore: (_, params) => {
            const application = params.applicationRes.result;

            if (!application) {
              return false;
            }

            const isApprovedContingent = application.statusCodes?.some(
              (statusCode) =>
                statusCode ===
                APPLICATION_STATUS_CODES.DecisionApprovedApprovedContingent
            );

            return !!(
              namespace === NAMESPACE_MAP.InStore && isApprovedContingent
            );
          },
          needsIDVerification: (_, params) => {
            const app = params.applicationRes.result;
            if (!app) {
              return false;
            }
            return (
              !enableAlloyJourney &&
              enableIDVerification &&
              needsIDVerification(app)
            );
          },
          needsIDVerificationAlloy: (_, params) => {
            const app = params.applicationRes.result;
            if (!app) {
              return false;
            }
            return (
              enableIDVerification &&
              enableAlloyJourney &&
              needsIDVerification(app)
            );
          },
          isCheckoutCompleted: (_, params) => {
            const status = params.applicationRes.result?.status;
            return status === APPLICATION_STATUS.CHECKOUT_COMPLETED;
          },
          isDownPaymentAuthDeclinedError: (_, params) => {
            const app = params.applicationRes.result;
            if (!app) {
              return false;
            }

            return !!app.statusCodes?.some(
              (code) =>
                code ===
                APPLICATION_STATUS_CODES.DownPaymentAuthDeclinedDeclinedRetryPaymentMethod
            );
          },
          isCheckoutPrepared: (_, params) => {
            const status = params.applicationRes.result?.status;
            return status === APPLICATION_STATUS.CHECKOUT_PREPARED;
          },
          isErrorResult: (_, params) => {
            const error = params.applicationRes.error;
            return !!(error?.reason || error?.message);
          },
          isVirtualCardApplication: () => {
            return isVirtualCard;
          },
          isIDVerificationSuccess: (_, params) => {
            const resultResponse = params.evaluateIDResponse.result;
            if (!resultResponse) {
              return false;
            }
            const { statusCode } = resultResponse;

            return (
              statusCode === APPLICATION_STATUS_CODES.KYCPassed ||
              statusCode === APPLICATION_STATUS_CODES.FraudIncompleteLowRisk
            );
          },
          isIDVerificationFormError: (_, params) => {
            return !!params.evaluateIDResponse.error;
          },
          isIDVerificationNeedsActionWarning: (_, params) => {
            const resultResponse = params.evaluateIDResponse.result;
            if (!resultResponse) {
              return false;
            }
            const { statusCode } = resultResponse;
            if (needsManualReview(statusCode) || hasFailed(statusCode)) {
              return (
                statusCode ===
                  ID_VERIFICATION_STATUS_CODES.DocumentEvaluationExtendedFraudAlert ||
                statusCode ===
                  ID_VERIFICATION_STATUS_CODES.DocumentEvaluationHighRisk
              );
            }
            return false;
          },
          isIDVerificationFailure: (_, params) => {
            const resultResponse = params.evaluateIDResponse.result;
            if (!resultResponse) {
              return false;
            }
            const { statusCode } = resultResponse;
            if (needsManualReview(statusCode) || hasFailed(statusCode)) {
              return (
                statusCode === ID_VERIFICATION_STATUS_CODES.KYCFailIDDenial
              );
            }
            return false;
          },
          isIDVerificationRetryError: (_, params) => {
            const resultResponse = params.evaluateIDResponse.result;
            if (!resultResponse) {
              return false;
            }
            const {
              metadata: { attempts, maxAttempts },
            } = resultResponse;

            return !!(attempts && maxAttempts && attempts < maxAttempts);
          },
          isIDVerificationErrorMaxAttemptsExceeded: (_, params) => {
            const resultResponse = params.evaluateIDResponse.result;
            if (!resultResponse) {
              return false;
            }
            const {
              metadata: { attempts, maxAttempts },
            } = resultResponse;

            return !!(attempts && maxAttempts && attempts >= maxAttempts);
          },
          isPoBoxAddressIneligible: (_, params) => {
            const statusCode = params.statusCode;
            return (
              statusCode ===
              APPLICATION_STATUS_CODES.EligibilityIneligibleInvalidAddressPOBox
            );
          },
          isBuyerHashFailed: (_, params) => {
            const statusCode = params.statusCode;
            return (
              statusCode ===
              APPLICATION_STATUS_CODES.ApplicationCancelledBuyerHashCheckFailed
            );
          },
          isCreditFreeze: (_, params) => {
            const statusCode = params.statusCode;
            return [
              APPLICATION_STATUS_CODES.DecisionIncompleteIncompleteCreditFreeze,
              APPLICATION_STATUS_CODES.KYCIncompleteCreditFreeze,
            ].includes(statusCode);
          },
          isKycDenial: (_, params) => {
            const statusCode = params.statusCode;
            return [
              APPLICATION_STATUS_CODES.DecisionDeniedInsufficientData,
              APPLICATION_STATUS_CODES.KYCFailed,
            ].includes(statusCode);
          },
          isPreviousDenialIneligible: (_, params) => {
            const statusCode = params.statusCode;
            return (
              statusCode ===
              APPLICATION_STATUS_CODES.DecisionIneligiblePreviousDenial
            );
          },
          isMaxCardIneligible: (_, params) => {
            const statusCode = params.statusCode;
            return (
              statusCode ===
              APPLICATION_STATUS_CODES.DecisionIneligibleMaxCardAttempt
            );
          },
          isOutstandingLoansIneligible: (_, params) => {
            const statusCode = params.statusCode;
            return (
              statusCode ===
              APPLICATION_STATUS_CODES.DecisionIneligibleOutstandingLoans
            );
          },
          isAgeIneligible: (_, params) => {
            const statusCode = params.statusCode;
            return (
              statusCode ===
              APPLICATION_STATUS_CODES.EligibilityIneligibleBuyerAge
            );
          },
          isAgeAlabamaMilitary: (_, params) => {
            const statusCode = params.statusCode;
            return (
              statusCode ===
              APPLICATION_STATUS_CODES.EligibilityIneligibleBuyerAgeAL
            );
          },
          isBuyerStatusIneligible: (_, params) => {
            const statusCode = params.statusCode;
            return (
              statusCode ===
              APPLICATION_STATUS_CODES.EligibilityIneligibleBuyerStatus
            );
          },
          isBuyerSkippedInstallments: (_, params) => {
            const statusCode = params.statusCode;
            return (
              statusCode ===
              APPLICATION_STATUS_CODES.DecisionIneligibleIneligibleSkippedInstallments
            );
          },
          isLocationIneligible: (_, params) => {
            const statusCode = params.statusCode;
            return [
              APPLICATION_STATUS_CODES.EligibilityIneligibleBuyerRegion,
              APPLICATION_STATUS_CODES.EligibilityIneligibleBuyerCountry,
            ].includes(statusCode);
          },
          isNeedsActionWarning: (_, params) => {
            const statusCode = params.statusCode;
            return [
              APPLICATION_STATUS_CODES.FraudIncompleteExtendedFraudAlert,
              APPLICATION_STATUS_CODES.FraudIncompleteHighRisk,
              APPLICATION_STATUS_CODES.FraudIncompleteLowRisk,
              APPLICATION_STATUS_CODES.KYCIncompleteFailed,
            ].includes(statusCode);
          },
          isFraudDenial: (_, params) => {
            const statusCode = params.statusCode;
            return statusCode === APPLICATION_STATUS_CODES.FraudFailed;
          },
          isCreditDenial: (_, params) => {
            const statusCode = params.statusCode;
            return [
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedCredit,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedNoCreditFile,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedNoCreditScore,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedThinFile,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedRepossessions,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedForeclosure,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedDerogatoryRecord,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedTradePastDue,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedCollections,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedRevolvingCreditUtilization,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedInsufficientCapacity,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedAuthorizedTradelines,
              APPLICATION_STATUS_CODES.DecisionDeniedLowCreditLimit,
              APPLICATION_STATUS_CODES.DecisionDeniedLowFicoLowCreditLimit,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedLowFICO,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedCrust,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedBreadChargeOff,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedPastLoanOverdue,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedLoanCurrentlyOverdue,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedChargeOff,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedAccountPastDue,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedInquiries,
              APPLICATION_STATUS_CODES.DownPaymentAuthDeclined,
              APPLICATION_STATUS_CODES.DecisionDeniedDenied,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedCreditAbuse,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedCreditopticsDeceased,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedCreditopticsDecline,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedBureauDeceased,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedPreciseidDeceased,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedPreciseidDecline,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedEquifaxDeceased,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedBureauData,
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedLowBureau,
              APPLICATION_STATUS_CODES.FraudDeniedPreciseIDDenied,
            ].includes(statusCode);
          },
          isSanctionsOFACDenial: (_, params) => {
            const statusCode = params.statusCode;
            return (
              statusCode === APPLICATION_STATUS_CODES.SanctionsIncompleteFailed
            );
          },
          isSanctionsDenial: (_, params) => {
            const statusCode = params.statusCode;
            return statusCode === APPLICATION_STATUS_CODES.SanctionsFailed;
          },
          isFraudAlertDenial: (_, params) => {
            const statusCode = params.statusCode;
            return (
              statusCode ===
              APPLICATION_STATUS_CODES.DecisionDeniedDeniedFraudAlert
            );
          },
          isCapacityRecheck: (_, params) => {
            const statusCode = params.statusCode;
            return (
              statusCode ===
                APPLICATION_STATUS_CODES.CapacityCancelledContingent ||
              statusCode === APPLICATION_STATUS_CODES.CapacityCancelledPartial
            );
          },
        },
        actions: {
          assignApplication,
          assignApplicationWithAppendedStatusCodes,
          assignPaymentAgreementDocument,
          assignSelectedOffer,
          assignIDVerificationFrontImageData,
          assignIDVerificationBackImageData,
          resetContext: assign({
            ...initialContext,
          }),
          handleApplicationDone: (_, params) => {
            params.resolve(params.applicationRes);
          },
          handleIDVerificationDone: (_, params) => {
            params.resolve(params.evaluateIDResponse);
          },
          handleApplicationServiceError: (_, params) => {
            params.reject(params.error);
          },
          handleApplicationAnalytics: (_, params) => {
            const app = params.applicationRes.result;
            if (app) {
              setApplicationAnalytics(app);
            }
          },
          handleSelectedOfferAnalytics: (_, params) => {
            setSelectedOfferAnalytics(params.selectedOffer);
          },
          handleOnApproved: (_, params) => {
            const application = params.applicationRes.result;

            if (application) {
              onApproved?.(
                filterApplicationCapacity(application, canViewCapacity)
              );
            }
          },
          handleOnCheckout: (_, params) => {
            const application = params.applicationRes.result;

            /**
             * Report if not application was passed in event. Remove after confirming
             * in production. This should not be possible
             */
            if (!application) {
              logger.error(
                { buyerID: contextBuyer?.id },
                'handleOnCheckout called without application'
              );
              return;
            }

            /**
             * Report if integrator onCheckout event is missing. This should not be possible unless
             * this is a carts experience
             */
            if (!onCheckout) {
              logger.error(
                { applicationID: application.id, buyerID: contextBuyer?.id },
                'handleOnCheckout called without onCheckout callback'
              );
              return;
            }

            if (application) {
              const checkoutData = filterApplicationCapacity(
                application,
                canViewCapacity
              );
              logger.info(
                {
                  applicationID: checkoutData.id,
                  buyerID: checkoutData.buyerID,
                },
                'handleOnCheckout called'
              );
              onCheckout?.(checkoutData);
            }
          },
          logError: ({ context: ctx }, params) => {
            logger.error(
              {
                err: params.error,
                merchantID,
                applicationID: ctx.application?.id,
                buyerID: ctx.application?.buyerID,
              },
              `Error in ApplicationMachineContext: ${params.type}`
            );
          },
        },
        actors: {
          fetchApplication: fromPromise(async ({ input }) => {
            const { applicationID, resolve, reject } = input;
            try {
              const applicationRes = await getApplication(
                applicationID,
                apiFetch
              );
              return { applicationRes, resolve, reject };
            } catch (error) {
              throw { error, reject };
            }
          }),
          fetchLatestApplication: fromPromise<FetchLatestApplicationResult>(
            async () => {
              const applicationRes = await getLatestApplication(apiFetch);
              return {
                applicationRes: toResultResponse<Application | null>(
                  applicationRes
                ),
              };
            }
          ),
          createApplication: fromPromise<CreateApplicationResult>(async () => {
            if (!order) {
              throw new Error('Cannot create an application without an order!');
            }
            const disclosures: DisclosuresAttribute =
              program.policies.application.tof.disclosures?.map(
                (disclosure) => ({
                  type: disclosure.type,
                })
              ) || [];

            const extensions: Application['extensions'] = [
              ...(locationID && locationID !== ZERO_UUID
                ? [{ key: 'locationID', value: locationID }]
                : []),
              ...(cartID ? [{ key: 'cartID', value: cartID }] : []),
            ];

            const { items } = order;

            const applicationRes = await createApplication(
              {
                ...order,
                items: items ? stringifyShippingDescription(items, locale) : [],
              },
              extensions,
              disclosures,
              merchantOrigin,
              apiFetch
            );
            return { applicationRes };
          }),
          reCreateApplication: fromPromise(async ({ input }) => {
            const { resolve, reject, options } = input;

            try {
              const { order: optionsOrder } = options;
              const requestOrder = optionsOrder || order;

              if (!requestOrder) {
                throw new Error(
                  'Cannot create an application without an order!'
                );
              }

              const disclosures: DisclosuresAttribute =
                program.policies.application.tof.disclosures?.map(
                  (disclosure) => ({
                    type: disclosure.type,
                  })
                ) || [];

              const extensions: Application['extensions'] = [
                ...(locationID && locationID !== ZERO_UUID
                  ? [{ key: 'locationID', value: locationID }]
                  : []),
                ...(cartID ? [{ key: 'cartID', value: cartID }] : []),
              ];

              const { items } = requestOrder;

              const applicationRes = await createApplication(
                {
                  ...requestOrder,
                  items: items
                    ? stringifyShippingDescription(items, locale)
                    : [],
                },
                extensions,
                disclosures,
                merchantOrigin,
                apiFetch
              );
              return { applicationRes, resolve, reject };
            } catch (error) {
              throw { error, reject };
            }
          }),
          applicationCheckout: fromPromise(async ({ input }) => {
            const { resolve, reject, options, context: ctx } = input;

            try {
              const { application, paymentAgreementDocument, selectedOffer } =
                ctx;
              const {
                buyer,
                shippingContactID,
                iovationBlackbox,
                paymentMethodID,
                isSplitPay,
                neuroIdTokens,
              } = options;
              const { pickupInformation } = order;

              if (!application || !selectedOffer || !paymentAgreementDocument) {
                throw new Error(
                  'Application and offer required for application checkout!'
                );
              }

              const applicationRes = await applicationCheckout(
                {
                  application: {
                    ...application,
                    order: {
                      ...application.order,
                      ...(pickupInformation && {
                        pickupInformation: {
                          ...pickupInformation,
                          name: pickupInformation?.name || buyer?.identity.name,
                          email:
                            pickupInformation?.email || buyer?.identity.email,
                          phone:
                            pickupInformation?.phone || buyer?.identity.phone,
                        },
                      }),
                    },
                  },
                  shippingContactID,
                  paymentAgreementDocument,
                  paymentAgreementID: selectedOffer.paymentAgreement.id,
                  iovationBlackbox,
                  paymentMethodID,
                  isSplitPay,
                  neuroIdTokens,
                },
                apiFetch
              );

              return { applicationRes, resolve, reject };
            } catch (error) {
              throw { error, reject };
            }
          }),
          applicationPrepareCheckout: fromPromise(async ({ input }) => {
            const { resolve, reject, options, context: ctx } = input;
            try {
              const { application, paymentAgreementDocument, selectedOffer } =
                ctx;
              const {
                iovationBlackbox,
                paymentMethodID,
                isSplitPay,
                neuroIdTokens,
              } = options;

              if (!application || !selectedOffer || !paymentAgreementDocument) {
                throw new Error(
                  'Application and offer required for application checkout!'
                );
              }

              const applicationRes = await applicationPrepareCheckout(
                {
                  application,
                  paymentAgreementDocument,
                  iovationBlackbox,
                  isSplitPay,
                  paymentMethodID,
                  paymentAgreementID: selectedOffer.paymentAgreement.id,
                  neuroIdTokens,
                },
                apiFetch
              );

              const appResult = applicationRes.result;

              // Make sure the offers for new estimated spend order values are valid
              if (appResult) {
                const validatedOffers = validateOfferProductTypes(
                  appResult?.offers || [],
                  merchantPaymentProducts
                );

                const applicationResWithValidatedOffers =
                  toResultResponse<Application>({
                    ...appResult,
                    offers: validatedOffers,
                  });
                return {
                  applicationRes: applicationResWithValidatedOffers,
                  resolve,
                  reject,
                };
              }

              return { applicationRes, resolve, reject };
            } catch (error) {
              throw { error, reject };
            }
          }),
          prepareInStoreApplication: fromPromise(async ({ input }) => {
            const app = input.application;

            if (!app) {
              throw new Error('Missing context while preparing application!');
            }

            const offers = validateOfferProductTypes(
              app.offers || [],
              merchantPaymentProducts
            );

            const applicationResult: Application = {
              ...app,
              offers,
            };

            if (applicationResult.paymentMethodID) {
              const paymentMethod = await getPaymentMethod(
                apiFetch,
                applicationResult.paymentMethodID,
                app.buyerID
              );
              setPaymentMethodID(paymentMethod.id);
              setPaymentMethods([paymentMethod]);
            }

            const approvedPaymentProductIDs = offers.map(
              (offer) => offer.paymentProduct.id
            );

            const approvedPaymentProducts = merchantPaymentProducts?.filter(
              (pp) => approvedPaymentProductIDs.includes(pp.paymentProduct.id)
            );

            const paymentAgreementsByType = groupOffersByType(offers);

            const {
              INSTALLMENTS: installmentsMaxCapacity,
              SPLITPAY: splitPayMaxCapacity,
            } = getMaxCapacities(
              paymentAgreementsByType,
              approvedPaymentProducts
            );

            setEstimatedSpend({
              INSTALLMENTS: installmentsMaxCapacity.value / 100,
              SPLITPAY: splitPayMaxCapacity.value / 100,
            });

            const applicationRes =
              toResultResponse<Application>(applicationResult);

            return { applicationRes };
          }),
          // change to VirtualCardIssuanceResponse
          prepareVirtualCardIssuance: fromPromise(async ({ input }) => {
            const app = input.application;
            if (!app) {
              throw new Error(
                'Cannot prepare virtual issuance without an application!'
              );
            }
            const issuanceResponse = await pollVirtualCardIssuance(
              `APPLICATION.${app.id}`,
              apiFetch
            );

            if (
              issuanceResponse.error ||
              issuanceResponse.result.issuance.status !== 'ISSUED' ||
              issuanceResponse.result.card?.status === 'FAILED'
            ) {
              throw new Error(
                'Virtual Card Error: Error obtaining virtual card issuance.'
              );
            }

            setVirtualCardIssuance(issuanceResponse.result);

            return { issuanceResponse };
          }),
          reCreateInStoreContingentApplication: fromPromise(
            async ({ input }) => {
              const app = input.applicationRes.result;

              if (!app) {
                throw new Error(
                  'Cannot recreate a contingent application without an existing application!'
                );
              }

              const updatedOrder: Order = {
                items: [],
                subTotal: app.capacity,
                totalDiscounts: usd(0),
                totalPrice: app.capacity,
                totalShipping: usd(0),
                totalTax: usd(0),
              };

              const { items } = updatedOrder;

              const disclosures: DisclosuresAttribute =
                program.policies.application.tof.disclosures?.map(
                  (disclosure) => ({
                    type: disclosure.type,
                  })
                ) || [];

              const extensions: Application['extensions'] = [
                ...(locationID && locationID !== ZERO_UUID
                  ? [{ key: 'locationID', value: locationID }]
                  : []),
                ...(cartID ? [{ key: 'cartID', value: cartID }] : []),
              ];

              const applicationRes = await createApplication(
                {
                  ...updatedOrder,
                  items: items
                    ? stringifyShippingDescription(items, locale)
                    : [],
                },
                extensions,
                disclosures,
                merchantOrigin,
                apiFetch
              );
              return { applicationRes };
            }
          ),
          evaluateIDVerificationImages: fromPromise(async ({ input }) => {
            const { resolve, reject, options, context: ctx } = input;
            try {
              const { buyer } = options;
              const {
                application,
                selectedOffer,
                idVerification: { frontImageData, backImageData },
              } = ctx;

              if (
                !application ||
                !selectedOffer ||
                !frontImageData ||
                !backImageData ||
                !buyer?.id
              ) {
                throw new Error(
                  'Cannot evaluate ID Verification images without required data!'
                );
              }

              const uploadFrontResponse = await uploadIDVerificationImage(
                apiFetch,
                buyer.id,
                frontImageData
              );
              if (
                !uploadFrontResponse.result?.imageID &&
                uploadFrontResponse.error
              ) {
                return {
                  evaluateIDResponse: toErrorResponse(
                    uploadFrontResponse.error
                  ),
                  resolve,
                  reject,
                };
              } else if (
                !uploadFrontResponse.result?.imageID &&
                !uploadFrontResponse.error
              ) {
                throw new Error('Error uploading ID image!');
              }

              const uploadBackResponse = await uploadIDVerificationImage(
                apiFetch,
                buyer.id,
                backImageData
              );
              if (
                !uploadBackResponse?.result?.imageID &&
                uploadBackResponse.error
              ) {
                return {
                  evaluateIDResponse: uploadBackResponse,
                  resolve,
                  reject,
                };
              } else if (
                !uploadBackResponse?.result?.imageID &&
                !uploadBackResponse.error
              ) {
                throw new Error('Error uploading ID image!');
              }

              const evaluateIDResponse = await evaluateIDVerificationImages(
                apiFetch,
                application.id,
                getOriginFromStatus(application.statusCodes || []),
                uploadFrontResponse.result.imageID,
                uploadBackResponse.result.imageID
              );

              return { evaluateIDResponse, resolve, reject };
            } catch (error) {
              throw { error, reject };
            }
          }),
          getErrorStatusCodeFromApplication: fromPromise(({ input }) => {
            return new Promise((resolve) => {
              const applicationEventDoneResult =
                (input as
                  | ApplicationPrepareCheckoutResult
                  | ApplicationCheckoutResult
                  | ReCreateApplicationResult
                  | ReCreateInStoreContingentApplicationResult
                  | ReCreateApplicationResult) || null;

              const application =
                applicationEventDoneResult.applicationRes.result;
              const statusCodes = application?.statusCodes || [];
              const statusCode =
                statusCodes.find(
                  (domainReason) =>
                    APPLICATION_STATUS_CODE_ERROR_MAP[domainReason]
                ) || '';
              resolve({ statusCode });
            });
          }),
        },
      }),
      {
        input: initialContext,
      }
    );

    // Ensure we re-create application if order changes
    useEffect(() => {
      if (
        lastOrderRef.current &&
        order &&
        !isEqual(order, lastOrderRef.current)
      ) {
        applicationService.send({ type: 'SEND_CREATE_APPLICATION' });
      }
      lastOrderRef.current = order;
    }, [order]);

    // Auth service subscriber
    useEffect(() => {
      if (
        authState.matches({
          authenticated: {
            complete: 'initial',
          },
        })
      ) {
        applicationService.send({ type: 'SEND_FETCH_LATEST_APPLICATION' });
        return;
      } else if (authState.matches('authenticated')) {
        applicationService.send({ type: 'SEND_APPLICATION_READY' });
      }
    }, [authState]);

    useEffect(() => {
      const state = applicationService.getSnapshot();

      // Buyers with an invalid application or no application - try to create a new application
      // only once the buyer has had a chance to enter all information
      if (state.matches({ ready: 'invalid' })) {
        if (
          buyerState.matches({ ready: { complete: 'dobUpdated' } }) ||
          buyerState.matches({ ready: { complete: 'dobUpdatedWithoutChange' } })
        ) {
          applicationService.send({ type: 'SEND_CREATE_APPLICATION' });
        }

        return;
      }

      // Do not fetch or create a new application if we already fetched or created one
      if (
        buyerState.matches({ ready: { complete: 'initial' } }) &&
        !state.matches({ ready: 'approved' })
      ) {
        // Complete buyer - try to fetch and then create if no approved application
        applicationService.send({ type: 'SEND_FETCH_LATEST_APPLICATION' });
      } else if (
        // Complete Buyer - updated contact or iin dob - Create a new application
        buyerState.matches({ ready: { complete: 'contactUpdated' } }) ||
        buyerState.matches({ ready: { complete: 'dobUpdated' } })
      ) {
        applicationService.send({ type: 'SEND_CREATE_APPLICATION' });
        // Complete Buyer - updated but iin dob or contact was not changed:
        // try to fetch and then create if no approved application
      } else if (
        buyerState.matches({
          ready: { complete: 'contactUpdatedWithoutChange' },
        }) ||
        buyerState.matches({ ready: { complete: 'dobUpdatedWithoutChange' } })
      ) {
        applicationService.send({ type: 'SEND_FETCH_LATEST_APPLICATION' });
        // RBC buyer with identity  - try to fetch and then create if no approved application
      } else if (buyerState.matches({ ready: 'completeIdentity' })) {
        applicationService.send({ type: 'SEND_FETCH_LATEST_APPLICATION' });
        // Complete Buyer - updated email address - Create a new application
      } else if (buyerState.matches({ ready: { complete: 'emailUpdated' } })) {
        applicationService.send({ type: 'SEND_CREATE_APPLICATION' });
      }
    }, [buyerState]);

    return (
      <ApplicationMachineContext.Provider value={applicationService}>
        {children}
      </ApplicationMachineContext.Provider>
    );
  };
