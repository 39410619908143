import { ActionEventParams } from '../../../../apply/lib/types';
import {
  RouteMachineServiceEvents,
  SendRouteApplicationErrorParams,
} from '../types/events';

type DefaultApplicationErrorRouteGuards = {
  isAgeAlabamaMilitaryError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isAgeIneligibleError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isBuyerHashedFailedError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isBuyerSkippedInstallmentsError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isBuyerStatusIneligibleError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isCreditDenialError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isCreditFreezeError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isKYCDenialError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isLocationIneligibleError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isMaxCardIneligibleError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isNeedsActionWarningError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isOFACDenialError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isOutstandingLoansIneligibleError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  poBoxAddressIneligibleError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isPreviousDenialIneligibleError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isSanctionsDenialError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isFraudAlertDenialError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isCapacityRecheckError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isFraudDenialError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isIDVerificationFailure: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isIDVerificationMaxAttemptsExceeded: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
  isIDVerificationNeedsActionWarning: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteApplicationErrorParams
  ) => boolean;
};

/**
 * Shared default guard conditions to route for Application state errors
 * responding to RouteMachineService event SendRouteApplicationError
 * Note: These can be overridden in the routing machine context if needed
 * @returns DefaultApplicationErrorRouteGuards
 */
export const getDefaultApplicationErrorRouteGuards =
  (): DefaultApplicationErrorRouteGuards => {
    return {
      isAgeAlabamaMilitaryError: (_, params) => {
        return params.state.matches({ errors: 'ageAlabamaMilitary' });
      },
      isAgeIneligibleError: (_, params) => {
        return params.state.matches({ errors: 'ageIneligible' });
      },
      isBuyerHashedFailedError: (_, params) => {
        return params.state.matches({ errors: 'buyerHashFailed' });
      },
      isBuyerSkippedInstallmentsError: (_, params) => {
        return params.state.matches({ errors: 'buyerSkippedInstallments' });
      },
      isBuyerStatusIneligibleError: (_, params) => {
        return params.state.matches({ errors: 'buyerStatusIneligible' });
      },
      isCreditDenialError: (_, params) => {
        return params.state.matches({ errors: 'creditDenial' });
      },
      isCreditFreezeError: (_, params) => {
        return params.state.matches({ errors: 'creditFreeze' });
      },
      isKYCDenialError: (_, params) => {
        return params.state.matches({ errors: 'kycDenial' });
      },
      isLocationIneligibleError: (_, params) => {
        return params.state.matches({ errors: 'locationIneligible' });
      },
      isMaxCardIneligibleError: (_, params) => {
        return params.state.matches({ errors: 'maxCardIneligible' });
      },
      isNeedsActionWarningError: (_, params) => {
        return params.state.matches({ errors: 'needsActionWarning' });
      },
      isOFACDenialError: (_, params) => {
        return params.state.matches({ errors: 'sanctionsOFACDenial' });
      },
      isOutstandingLoansIneligibleError: (_, params) => {
        return params.state.matches({ errors: 'outstandingLoansIneligible' });
      },
      poBoxAddressIneligibleError: (_, params) => {
        return params.state.matches({ errors: 'poBoxAddressIneligible' });
      },
      isPreviousDenialIneligibleError: (_, params) => {
        return params.state.matches({ errors: 'previousDenialIneligible' });
      },
      isSanctionsDenialError: (_, params) => {
        return params.state.matches({ errors: 'sanctionsDenial' });
      },
      isFraudAlertDenialError: (_, params) => {
        return params.state.matches({ errors: 'fraudAlertDenial' });
      },
      isCapacityRecheckError: (_, params) => {
        return params.state.matches({ errors: 'capacityRecheck' });
      },
      isFraudDenialError: (_, params) => {
        return params.state.matches({ errors: 'fraudDenial' });
      },
      isIDVerificationFailure: (_, params) => {
        return params.state.matches({ errors: 'idVerificationFailure' });
      },
      isIDVerificationMaxAttemptsExceeded: (_, params) => {
        return params.state.matches({
          errors: 'idVerificationMaxAttemptsExceeded',
        });
      },
      isIDVerificationNeedsActionWarning: (_, params) => {
        return params.state.matches({
          errors: 'idVerificationNeedsActionWarning',
        });
      },
    };
  };
