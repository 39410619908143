import { createContext, useEffect } from 'react';
import { ActorRefFrom, fromPromise, assign } from 'xstate';
import { useActorRef } from '@xstate/react';
import { useRouter } from 'next/router';
import { isEqual } from 'lodash';
import { isRight } from 'fp-ts/lib/Either';
import { APIError, Buyer } from '@ads-bread/shared/bread/codecs';
import { FCWithChildren } from '../../lib/types';
import { useFetch } from '../../lib/hooks/apiFetch';
import { useAddressSchema } from '../../lib/hooks/useAddressSchema';
import { useNamespace } from '../../lib/hooks/useNamespace';
import {
  identify,
  setAdditionalContext as setAdditionalAnalyticsContext,
} from '../../lib/analytics';
import { logger } from '../../lib/logger';
import { toResultResponse } from '../../lib/handlers/base';
import { updateBuyer } from '../../lib/handlers/update-buyer';
import { getBuyer, getBuyerPhoneAndEmail } from '../../lib/handlers/get-buyer';
import { getCart } from '../../lib/handlers/get-cart';
import { createBuyer } from '../../lib/handlers/create-buyer';
import { updateContact } from '../../lib/handlers/update-contact';
import { useAppConfig } from '../AppConfigContext';
import { useBuyer, useMerchantID } from '../XPropsContext';
import { useAuthentication } from '../AuthenticationMachineContext';
import { buyerMachine, BuyerContext } from './buyerMachine';
import {
  assignBuyer,
  assignBuyerBillingAddress,
  assignBuyerDOB,
  assignBuyerEmail,
  assignBuyerIIN,
  assignBuyerName,
  assignBuyerPhone,
  assignBuyerShippingAddress,
  assignCartBuyer,
  assignPartialBuyer,
} from './assigns';
import {
  SendCreateBuyerResult,
  SendFetchBuyerResult,
  SendFetchPartialBuyerResult,
  PrepareCartBuyerResult,
  SendUpdateBuyerResult,
} from './types/actors';
import { SendCreateBuyerParams, SendUpdateBuyerParams } from './types/events';
import { isCompleteBuyer, isPrimaryContactUpdated } from './utils';
import { BUYER_ERROR_REASONS } from './constants/buyerReasons';
import { BUYER_ERROR_META_DATA_KEYS } from './constants/buyerErrorMetaKeys';

export type BuyerMachineActorRef = ActorRefFrom<typeof buyerMachine>;

export const BuyerMachineContext = createContext<BuyerMachineActorRef | null>(
  null
);

export type BuyerProviderProps = {
  context?: BuyerContext;
};

export const BuyerMachineProvider: FCWithChildren<BuyerProviderProps> = ({
  children,
  context = {},
}) => {
  const namespace = useNamespace();
  const { query } = useRouter();
  const xPropsBuyer = useBuyer();
  const { merchantID } = useMerchantID();
  const { buyerIdentityAttributes } = useAppConfig();
  const addressSchema = useAddressSchema();
  const { state: authState, logout } = useAuthentication();
  const apiFetch = useFetch();

  const initialContext: BuyerContext = {
    buyerName: context.buyerName || xPropsBuyer.name || null,
    buyerID: context.buyerID || xPropsBuyer.buyerID || null,
    email: context.email || xPropsBuyer.email || null,
    phone: context.phone || xPropsBuyer.phone || null,
    iin: context.iin || null,
    dob: context.dob || null,
    shippingAddress:
      context.shippingAddress || xPropsBuyer.shippingAddress || null,
    billingAddress:
      context.billingAddress || xPropsBuyer.billingAddress || null,
    buyer: context.buyer || null,
  };

  const buyerService = useActorRef(
    buyerMachine.provide({
      guards: {
        isCartBuyer: () => {
          return namespace === 'cart/[cartID]';
        },
        isCartComplete: (_, params) => {
          const cartDataContact = params.cartRes.result?.contact;
          if (!cartDataContact) {
            return false;
          }
          return !!(
            cartDataContact.name &&
            cartDataContact.billingAddress &&
            addressSchema.isValidSync(cartDataContact.billingAddress)
          );
        },
        isErrorResponse: (_, params) => {
          return !!params.buyerRes.error;
        },
        isComplete: (_, params) => {
          const buyer = params.buyerRes.result;

          const isComplete = !!(
            buyer &&
            isCompleteBuyer(buyer, buyerIdentityAttributes, addressSchema)
          );

          return isComplete;
        },
        isCompleteAndContactUpdatedWithoutChange: (
          { context: ctx },
          params
        ): boolean => {
          const buyer = params.buyerRes.result;
          const contact = params.contactRes?.result;
          const currentBuyer = ctx.buyer;

          // There was no attempt to update contact
          if (!contact) {
            return false;
          }

          const isContactUpdated = isPrimaryContactUpdated(
            currentBuyer,
            contact
          );
          const isComplete = !!(
            buyer &&
            isCompleteBuyer(buyer, buyerIdentityAttributes, addressSchema)
          );

          return !isContactUpdated && isComplete;
        },
        isCompleteAndDobUpdatedWithoutChange: (
          { context: ctx },
          params
        ): boolean => {
          const buyer = params.buyerRes.result;
          const eventBuyerIdentity = params.identity;

          // There was no attempt to update dob in event
          if (!eventBuyerIdentity.birthDate) {
            return false;
          }

          const currentBirthDate = ctx.buyer?.identity.birthDate;
          const resultBirthDate = buyer?.identity.birthDate;

          const isIinDobUpdatedWithoutChange =
            currentBirthDate === resultBirthDate;

          const isComplete =
            buyer &&
            isCompleteBuyer(buyer, buyerIdentityAttributes, addressSchema);

          return !!(isIinDobUpdatedWithoutChange && isComplete);
        },
        isCompleteAndDobUpdated: ({ context: ctx }, params): boolean => {
          const buyer = params.buyerRes.result;
          const eventBuyerIdentity = params.identity;

          // There was no attempt to update dob in event
          if (!eventBuyerIdentity.birthDate) {
            return false;
          }

          const currentBirthDate = ctx.buyer?.identity.birthDate;
          const resultBirthDate = buyer?.identity.birthDate;

          const isIinDobUpdated = currentBirthDate !== resultBirthDate;

          const isComplete =
            buyer &&
            isCompleteBuyer(buyer, buyerIdentityAttributes, addressSchema);

          return !!(isIinDobUpdated && isComplete);
        },
        isCompleteAndContactUpdated: (_, params) => {
          const buyer = params.buyerRes.result;
          const isComplete = !!(
            buyer &&
            isCompleteBuyer(buyer, buyerIdentityAttributes, addressSchema)
          );
          const contactUpdated = !!params.contactRes?.result;
          return isComplete && contactUpdated;
        },
        hasCompleteIdentity: (_, params) => {
          const buyer = params.buyerRes.result;
          const isComplete = !!(
            buyer &&
            buyerIdentityAttributes.every((attr) => !!buyer.identity[attr])
          );

          return isComplete;
        },
        isValidDecodedBuyer: (_, params) => {
          const decoded = Buyer.decode(params.buyerRes.result);
          return isRight(decoded);
        },
        isPartialBuyerSuccessResponse: (_, params) => {
          return !!params.buyerRes.result;
        },
        isBuyerConflictErrorResponse: (_, params) => {
          return (
            BUYER_ERROR_REASONS.BUYER_CONFLICT === params.buyerRes.error?.reason
          );
        },
        hasBuyerResult: (_, params) => {
          return !!params.buyerRes.result;
        },
        isEmailUpdated: ({ context: ctx }, params) => {
          const buyer = params.buyerRes.result;
          const eventBuyerIdentity = params.identity;
          // There was no attempt to update email in event
          if (!eventBuyerIdentity.email) {
            return false;
          }
          const currentEmail = ctx.buyer?.identity.email;
          const resultEmail = buyer?.identity.email;
          const isEmailUpdated = currentEmail !== resultEmail;
          const isComplete =
            buyer &&
            isCompleteBuyer(buyer, buyerIdentityAttributes, addressSchema);
          return !!(isEmailUpdated && isComplete);
        },
        isVerifiedEmailError: (_, params) => {
          const reason = params.reason;
          return [
            BUYER_ERROR_REASONS.IDENTITY_MISMATCH,
            BUYER_ERROR_REASONS.BUYER_VALIDATION,
            BUYER_ERROR_REASONS.EMAIL_ALREADY_EXISTS,
            BUYER_ERROR_REASONS.PHONE_ALREADY_EXISTS,
          ].includes(reason);
        },
        isBuyerValidationError: (_, params): boolean => {
          return (
            BUYER_ERROR_REASONS.BUYER_VALIDATION ===
              params.buyerRes.error?.reason &&
            !!params.buyerRes.error.metadata[BUYER_ERROR_META_DATA_KEYS.IIN]
          );
        },
      },
      actions: {
        assignBuyer,
        assignPartialBuyer,
        assignCartBuyer,
        assignBuyerName,
        assignBuyerEmail,
        assignBuyerPhone,
        assignBuyerIIN,
        assignBuyerDOB,
        assignBuyerShippingAddress,
        assignBuyerBillingAddress,
        resetContext: assign({
          ...initialContext,
        }),
        identifyBuyer: (_, params) => {
          const buyerId = params.buyerRes.result?.id;
          if (buyerId) {
            identify(buyerId);
          }
        },
        handleBuyerEmailAnalytics: (_, params) => {
          const email = params.email;
          if (email) {
            setAdditionalAnalyticsContext({ email });
          }
        },
        handleBuyerPhoneAnalytics: (_, params) => {
          const phone = params.phone;
          if (phone) {
            setAdditionalAnalyticsContext({ phone });
          }
        },
        handleBuyerDone: (_, params) => {
          params.resolve(params.buyerRes);
        },
        handleServiceError: (_, params) => {
          params.reject(params.error);
        },
        sendEvaluateBuyerError: (_, __) => {
          throw new Error('Not implemented');
        },
        logout: () => {
          logout();
        },
        logError: ({ context: ctx }, params) => {
          logger.error(
            { err: params.error, buyerID: ctx.buyer?.id, merchantID },
            `Error in BuyerMachineContext: ${params.type}`
          );
        },
      },
      actors: {
        createBuyer: fromPromise<SendCreateBuyerResult, SendCreateBuyerParams>(
          async ({ input }) => {
            const resolve = input.resolve;
            const reject = input.reject;

            try {
              const buyer = input.buyer;
              const buyerRes = await createBuyer(buyer, apiFetch);

              if (!input.contact) {
                return { buyerRes: buyerRes, resolve, reject };
              }
              // Update the contact if passed
              if (buyerRes.result) {
                const contactRes = await updateContact(
                  buyerRes.result,
                  input.contact,
                  apiFetch
                );

                if (contactRes.result) {
                  const updatedBuyer = {
                    ...buyerRes.result,
                    contacts: {
                      ...buyerRes.result.contacts,
                      [buyerRes.result.primaryContactID]: contactRes.result,
                    },
                  };
                  // Just return the concatenated result response if successful
                  return {
                    buyerRes: toResultResponse(updatedBuyer),
                    resolve,
                    reject,
                  };
                }
              }

              return { buyerRes, resolve, reject };
            } catch (error) {
              throw { error, reject };
            }
          }
        ),
        updateBuyer: fromPromise<SendUpdateBuyerResult, SendUpdateBuyerParams>(
          async ({ input }) => {
            const resolve = input.resolve;
            const reject = input.reject;
            const contextBuyer = input.context.buyer;
            try {
              if (!contextBuyer) {
                throw new Error('Error updating buyer. No buyer in context.');
              }

              const updatedBuyer: Buyer = {
                ...contextBuyer,
                ...input.buyer,
                identity: {
                  ...contextBuyer.identity,
                  ...input.buyer.identity,
                },
                employment: {
                  ...contextBuyer.employment,
                  ...input.buyer?.employment,
                },
              };

              if (input.contact) {
                const buyerRes = await updateBuyer(updatedBuyer, apiFetch);

                // Don't bother updating contact if buyer update failed
                if (buyerRes.error) {
                  return {
                    buyerRes,
                    identity: input.buyer.identity,
                    resolve,
                    reject,
                  };
                }

                const contactRes = await updateContact(
                  updatedBuyer,
                  input.contact,
                  apiFetch
                );
                if (contactRes.error) {
                  return {
                    buyerRes: contactRes,
                    identity: input.buyer.identity,
                    resolve,
                    reject,
                  };
                }

                // Append the updated contact
                const updatedBuyerWithContact = {
                  ...buyerRes.result,
                  contacts: {
                    ...buyerRes.result.contacts,
                    [buyerRes.result.primaryContactID]: contactRes.result,
                  },
                };

                return {
                  buyerRes: toResultResponse(updatedBuyerWithContact),
                  contactRes,
                  identity: input.buyer.identity,
                  resolve,
                  reject,
                };
              } else {
                const otherBuyerRes = await updateBuyer(updatedBuyer, apiFetch);

                return {
                  buyerRes: otherBuyerRes,
                  identity: input.buyer.identity,
                  resolve,
                  reject,
                };
              }
            } catch (error) {
              throw { error, reject };
            }
          }
        ),
        fetchBuyer: fromPromise<SendFetchBuyerResult>(async () => {
          try {
            const buyerRes = await getBuyer(apiFetch);
            return { buyerRes };
          } catch (error) {
            logout();
            throw { error };
          }
        }),
        fetchPartialBuyer: fromPromise<SendFetchPartialBuyerResult>(
          async () => {
            try {
              if (!xPropsBuyer.buyerID) {
                throw new Error(
                  'Unable to fetch partial buyer without buyerID!'
                );
              }

              const buyerRes = await getBuyerPhoneAndEmail(
                apiFetch,
                xPropsBuyer.buyerID
              );
              return { buyerRes };
            } catch (error) {
              logout();
              throw { error };
            }
          }
        ),
        prepareCartBuyer: fromPromise<PrepareCartBuyerResult>(async () => {
          const { cartID } = query;
          if (!cartID || typeof cartID !== 'string') {
            throw new Error('Cannot prepare a cart without cartID param!');
          }
          const cartRes = await getCart(apiFetch, cartID);

          if (cartRes.error) {
            throw cartRes.error;
          }

          return { cartRes };
        }),
        getErrorReasonFromResponse: fromPromise(({ input }) => {
          const apiError = (input as APIError) || null;
          return new Promise((resolve) => {
            const reason = apiError?.reason || 'unknown';
            resolve({ reason });
          });
        }),
      },
    }),
    {
      input: initialContext,
    }
  );

  useEffect(() => {
    if (
      authState.matches({
        authenticated: 'complete',
      })
    ) {
      // Returning buyer
      buyerService.send({ type: 'SEND_FETCH_BUYER' });
      return;
    } else if (authState.matches('authenticated')) {
      buyerService.send({ type: 'SEND_BUYER_READY' });
    } else if (authState.matches({ unAuthenticated: 'exchanging' })) {
      // Exchanged buyer
      buyerService.send({ type: 'SEND_FETCHING_PARTIAL_BUYER' });
    }
  }, [authState, buyerService]);

  /**
   * Updates shipping address if xProps are updated via BreadSDK setShippingAddress
   */
  useEffect(() => {
    const { context: buyerServiceContext } = buyerService.getSnapshot();
    if (
      xPropsBuyer.shippingAddress &&
      !isEqual(buyerServiceContext.shippingAddress, xPropsBuyer.shippingAddress)
    ) {
      buyerService.send({
        type: 'SEND_UPDATE_BUYER_SHIPPING_ADDRESS',
        params: {
          shippingAddress: xPropsBuyer.shippingAddress,
        },
      });
    }
  }, [buyerService, xPropsBuyer.shippingAddress]);

  return (
    <BuyerMachineContext.Provider value={buyerService}>
      {children}
    </BuyerMachineContext.Provider>
  );
};
