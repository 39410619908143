import { fromPromise, setup } from 'xstate';
import { Buyer, Name, Address } from '@ads-bread/shared/bread/codecs';
import { ActorErrorParams } from '../../../apply/lib/types';
import {
  BuyerMachineEvents,
  SendCreateBuyerParams,
  SendUpdateBuyerEmailParams,
  SendUpdateBuyerParams,
  SendUpdateBuyerPhoneParams,
} from './types/events';
import {
  assignBuyer,
  assignBuyerBillingAddress,
  assignBuyerDOB,
  assignBuyerEmail,
  assignBuyerIIN,
  assignBuyerName,
  assignBuyerPhone,
  assignBuyerShippingAddress,
  assignCartBuyer,
  assignPartialBuyer,
  resetContext,
} from './assigns';
import {
  getCreateBuyerParams,
  getEvaluateBuyerErrorParams,
  getFetchBuyerParams,
  getFetchPartialBuyerParams,
  getPreparingCartParams,
  getServiceErrorParams,
  getUpdateBuyerBillingAddressParams,
  getUpdateBuyerDOBParams,
  getUpdateBuyerEmailParams,
  getUpdateBuyerIINParams,
  getUpdateBuyerNameParams,
  getUpdateBuyerParams,
  getUpdateBuyerPhoneParams,
  getUpdateBuyerShippingAddressParams,
} from './actions';
import {
  PrepareCartBuyerResult,
  SendCreateBuyerResult,
  SendFetchBuyerResult,
  SendFetchPartialBuyerResult,
  SendUpdateBuyerResult,
  EvaluateBuyerErrorResult,
} from './types/actors';
import {
  getCreateBuyerInput,
  getEvaluateBuyerErrorInput,
  getFetchBuyerInput,
  getFetchPartialBuyerInput,
  getPrepareCartBuyerInput,
  getUpdateBuyerInput,
} from './inputs';

export interface BuyerContext {
  buyer: Buyer | null;
  buyerID: string | null;
  buyerName: Name | null;
  email: string | null;
  phone: string | null;
  iin: string | null;
  dob: string | null;
  shippingAddress: Address | null;
  billingAddress: Address | null;
}

export const buyerMachine = setup({
  types: {
    context: {} as BuyerContext,
    events: {} as BuyerMachineEvents,
    input: {} as BuyerContext,
  },
  guards: {
    isCartBuyer: (): boolean => {
      throw new Error('Not implemented');
    },
    isCartComplete: (_, __: PrepareCartBuyerResult): boolean => {
      throw new Error('Not implemented');
    },
    isErrorResponse: (
      _,
      __: SendFetchBuyerResult | SendCreateBuyerResult
    ): boolean => {
      throw new Error('Not implemented');
    },
    isComplete: (
      _,
      __: SendFetchBuyerResult | SendCreateBuyerResult
    ): boolean => {
      throw new Error('Not implemented');
    },
    isEmailUpdated: (_, __: SendUpdateBuyerResult): boolean => {
      throw new Error('Not implemented');
    },
    isCompleteAndContactUpdated: (_, __: SendUpdateBuyerResult): boolean => {
      throw new Error('Not implemented');
    },
    isCompleteAndContactUpdatedWithoutChange: (
      _,
      __: SendUpdateBuyerResult
    ): boolean => {
      throw new Error('Not implemented');
    },
    isCompleteAndDobUpdatedWithoutChange: (
      _,
      __: SendUpdateBuyerResult
    ): boolean => {
      throw new Error('Not implemented');
    },
    isCompleteAndDobUpdated: (_, __: SendUpdateBuyerResult): boolean => {
      throw new Error('Not implemented');
    },
    hasCompleteIdentity: (
      _,
      __: SendFetchBuyerResult | SendCreateBuyerResult
    ): boolean => {
      throw new Error('Not implemented');
    },
    isValidDecodedBuyer: (_, __: SendFetchBuyerResult): boolean => {
      throw new Error('Not implemented');
    },
    isPartialBuyerSuccessResponse: (
      _,
      __: SendFetchPartialBuyerResult
    ): boolean => {
      throw new Error('Not implemented');
    },
    isBuyerConflictErrorResponse: (_, __: SendCreateBuyerResult): boolean => {
      throw new Error('Not implemented');
    },
    hasBuyerResult: (_, __: SendCreateBuyerResult): boolean => {
      throw new Error('Not implemented');
    },
    isVerifiedEmailError: (_, __: EvaluateBuyerErrorResult): boolean => {
      throw new Error('Not implemented');
    },
    isBuyerValidationError: (_, __: SendUpdateBuyerResult): boolean => {
      throw new Error('Not implemented');
    },
  },
  actions: {
    assignBuyer,
    assignPartialBuyer,
    assignCartBuyer,
    assignBuyerName,
    assignBuyerEmail,
    assignBuyerPhone,
    assignBuyerIIN,
    assignBuyerDOB,
    assignBuyerShippingAddress,
    assignBuyerBillingAddress,
    resetContext,
    identifyBuyer: (_, __: SendFetchBuyerResult | SendCreateBuyerResult) => {
      throw new Error('Not implemented');
    },
    handleBuyerEmailAnalytics: (_, __: SendUpdateBuyerEmailParams) => {
      throw new Error('Not implemented');
    },
    handleBuyerPhoneAnalytics: (_, __: SendUpdateBuyerPhoneParams) => {
      throw new Error('Not implemented');
    },
    handleBuyerDone: (_, __: SendCreateBuyerResult | SendUpdateBuyerResult) => {
      throw new Error('Not implemented');
    },
    handleServiceError: (_, __: ActorErrorParams): void => {
      throw new Error('Not implemented');
    },
    sendEvaluateBuyerError: (
      _,
      __: SendFetchBuyerResult | SendCreateBuyerResult | SendUpdateBuyerResult
    ) => {
      throw new Error('Not implemented');
    },
    logout: () => {
      throw new Error('Not implemented');
    },
    logError: (_, __: ActorErrorParams): void => {
      throw new Error('Not implemented');
    },
  },
  actors: {
    createBuyer: fromPromise<SendCreateBuyerResult, SendCreateBuyerParams>(
      () => {
        throw new Error('Not implemented');
      }
    ),
    updateBuyer: fromPromise<SendUpdateBuyerResult, SendUpdateBuyerParams>(
      () => {
        throw new Error('Not implemented');
      }
    ),
    fetchBuyer: fromPromise<SendFetchBuyerResult>(() => {
      throw new Error('Not implemented');
    }),
    fetchPartialBuyer: fromPromise<SendFetchPartialBuyerResult>(() => {
      throw new Error('Not implemented');
    }),
    prepareCartBuyer: fromPromise<PrepareCartBuyerResult>(() => {
      throw new Error('Not implemented');
    }),
    getErrorReasonFromResponse: fromPromise<EvaluateBuyerErrorResult, unknown>(
      () => {
        throw new Error('Not implemented');
      }
    ),
  },
}).createMachine({
  id: 'buyer',
  initial: 'initial',
  context: ({ input }) => {
    return {
      buyer: input.buyer || null,
      buyerID: input.buyerID || null,
      buyerName: input.buyerName || null,
      email: input.email || null,
      phone: input.phone || null,
      iin: input.iin || null,
      dob: input.dob || null,
      shippingAddress: input.shippingAddress || null,
      billingAddress: input.billingAddress || null,
    };
  },
  on: {
    SEND_UPDATE_BUYER_NAME: {
      actions: [
        {
          type: 'assignBuyerName',
          params: getUpdateBuyerNameParams,
        },
      ],
    },
    SEND_UPDATE_BUYER_EMAIL: {
      actions: [
        {
          type: 'assignBuyerEmail',
          params: getUpdateBuyerEmailParams,
        },
        {
          type: 'handleBuyerEmailAnalytics',
          params: getUpdateBuyerEmailParams,
        },
      ],
    },
    SEND_UPDATE_BUYER_PHONE: {
      actions: [
        {
          type: 'assignBuyerPhone',
          params: getUpdateBuyerPhoneParams,
        },
        {
          type: 'handleBuyerPhoneAnalytics',
          params: getUpdateBuyerPhoneParams,
        },
      ],
    },
    SEND_UPDATE_BUYER_IIN: {
      actions: [
        {
          type: 'assignBuyerIIN',
          params: getUpdateBuyerIINParams,
        },
      ],
    },
    SEND_UPDATE_BUYER_DOB: {
      actions: [
        {
          type: 'assignBuyerDOB',
          params: getUpdateBuyerDOBParams,
        },
      ],
    },
    SEND_UPDATE_BUYER_SHIPPING_ADDRESS: {
      actions: [
        {
          type: 'assignBuyerShippingAddress',
          params: getUpdateBuyerShippingAddressParams,
        },
      ],
    },
    SEND_UPDATE_BUYER_BILLING_ADDRESS: {
      actions: [
        {
          type: 'assignBuyerBillingAddress',
          params: getUpdateBuyerBillingAddressParams,
        },
      ],
    },
    SEND_RESET_BUYER_STATE: {
      target: '.initial',
      actions: 'resetContext',
    },
  },
  states: {
    initial: {
      on: {
        SEND_FETCH_BUYER: {
          target: 'fetchingBuyer',
        },
        SEND_FETCHING_PARTIAL_BUYER: {
          target: 'fetchingPartialBuyer',
        },
        SEND_BUYER_READY: [
          {
            guard: 'isCartBuyer',
            target: 'preparingCartBuyer',
          },
          {
            target: 'ready',
          },
        ],
      },
    },
    ready: {
      on: {
        SEND_UPDATE_BUYER: {
          target: 'updatingBuyer',
        },
        SEND_CREATE_BUYER: {
          target: 'creatingBuyer',
        },
        SEND_FETCH_BUYER: {
          target: 'fetchingBuyer',
        },
      },
      initial: 'initial',
      states: {
        initial: {},
        complete: {
          initial: 'initial',
          states: {
            initial: {},
            emailUpdated: {},
            contactUpdated: {},
            contactUpdatedWithoutChange: {},
            dobUpdated: {},
            dobUpdatedWithoutChange: {},
          },
        },
        incomplete: {},
        completeIdentity: {},
        exchanging: {},
        cartRetrieved: {},
      },
    },
    creatingBuyer: {
      invoke: {
        src: 'createBuyer',
        id: 'creatingBuyer',
        input: getCreateBuyerInput,
        onDone: [
          {
            guard: {
              type: 'isBuyerConflictErrorResponse',
              params: getCreateBuyerParams,
            },
            target: 'ready.initial',
          },
          {
            guard: {
              type: 'isErrorResponse',
              params: getCreateBuyerParams,
            },
            actions: [
              {
                type: 'handleBuyerDone',
                params: getCreateBuyerParams,
              },
            ],
            target: 'evaluateBuyerError',
          },
          {
            guard: {
              type: 'isComplete',
              params: getCreateBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getCreateBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getCreateBuyerParams,
              },
              {
                type: 'handleBuyerDone',
                params: getCreateBuyerParams,
              },
            ],
            target: 'ready.complete',
          },
          {
            guard: {
              type: 'hasCompleteIdentity',
              params: getCreateBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getCreateBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getCreateBuyerParams,
              },
              {
                type: 'handleBuyerDone',
                params: getCreateBuyerParams,
              },
            ],
            target: 'ready.completeIdentity',
          },
          {
            guard: {
              type: 'hasBuyerResult',
              params: getCreateBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getCreateBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getCreateBuyerParams,
              },
              {
                type: 'handleBuyerDone',
                params: getCreateBuyerParams,
              },
            ],
            target: 'ready.incomplete',
          },
          {
            target: 'ready.initial',
            actions: [
              {
                type: 'handleBuyerDone',
                params: getCreateBuyerParams,
              },
            ],
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'handleServiceError',
                params: getServiceErrorParams,
              },
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'ready.initial',
          },
        ],
      },
    },
    fetchingBuyer: {
      invoke: {
        src: 'fetchBuyer',
        input: getFetchBuyerInput,
        onDone: [
          {
            guard: {
              type: 'isErrorResponse',
              params: getFetchBuyerParams,
            },
            target: 'evaluateBuyerError',
          },
          {
            guard: {
              type: 'isComplete',
              params: getFetchBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getFetchBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getFetchBuyerParams,
              },
            ],
            target: 'ready.complete.initial',
          },
          {
            guard: {
              type: 'hasCompleteIdentity',
              params: getFetchBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getFetchBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getFetchBuyerParams,
              },
            ],
            target: 'ready.completeIdentity',
          },
          {
            guard: {
              type: 'isValidDecodedBuyer',
              params: getFetchBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getFetchBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getFetchBuyerParams,
              },
            ],
            target: 'ready.incomplete',
          },
          {
            actions: [
              {
                type: 'logout',
              },
            ],
            target: 'initial',
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'initial',
          },
        ],
      },
    },
    fetchingPartialBuyer: {
      invoke: {
        src: 'fetchPartialBuyer',
        input: getFetchPartialBuyerInput,
        onDone: [
          {
            guard: {
              type: 'isPartialBuyerSuccessResponse',
              params: getFetchPartialBuyerParams,
            },
            actions: [
              {
                type: 'assignPartialBuyer',
                params: getFetchPartialBuyerParams,
              },
            ],
            target: 'ready.exchanging',
          },
          {
            target: 'ready.initial',
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'ready.initial',
          },
        ],
      },
    },
    updatingBuyer: {
      invoke: {
        src: 'updateBuyer',
        id: 'updatingBuyer',
        input: getUpdateBuyerInput,
        onDone: [
          {
            guard: {
              type: 'isBuyerConflictErrorResponse',
              params: getUpdateBuyerParams,
            },
            actions: [
              {
                type: 'handleBuyerDone',
                params: getUpdateBuyerParams,
              },
            ],
            target: 'ready.initial',
          },
          {
            guard: {
              type: 'isBuyerValidationError',
              params: getUpdateBuyerParams,
            },
            actions: [
              {
                type: 'handleBuyerDone',
                params: getUpdateBuyerParams,
              },
            ],
            target: 'ready.initial',
          },
          {
            guard: {
              type: 'isErrorResponse',
              params: getUpdateBuyerParams,
            },
            actions: [
              {
                type: 'handleBuyerDone',
                params: getUpdateBuyerParams,
              },
            ],
            target: 'evaluateBuyerError',
          },
          {
            guard: {
              type: 'isEmailUpdated',
              params: getUpdateBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'handleBuyerDone',
                params: getUpdateBuyerParams,
              },
            ],
            target: 'ready.complete.emailUpdated',
          },
          {
            guard: {
              type: 'isCompleteAndDobUpdatedWithoutChange',
              params: getUpdateBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'handleBuyerDone',
                params: getUpdateBuyerParams,
              },
            ],
            target: 'ready.complete.dobUpdatedWithoutChange',
          },
          {
            guard: {
              type: 'isCompleteAndDobUpdated',
              params: getUpdateBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'handleBuyerDone',
                params: getUpdateBuyerParams,
              },
            ],
            target: 'ready.complete.dobUpdated',
          },
          {
            guard: {
              type: 'isCompleteAndContactUpdatedWithoutChange',
              params: getUpdateBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'handleBuyerDone',
                params: getUpdateBuyerParams,
              },
            ],
            target: 'ready.complete.contactUpdatedWithoutChange',
          },
          {
            guard: {
              type: 'isCompleteAndContactUpdated',
              params: getUpdateBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'handleBuyerDone',
                params: getUpdateBuyerParams,
              },
            ],
            target: 'ready.complete.contactUpdated',
          },
          {
            guard: {
              type: 'isComplete',
              params: getUpdateBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'handleBuyerDone',
                params: getUpdateBuyerParams,
              },
            ],
            target: 'ready.complete',
          },
          {
            guard: {
              type: 'hasCompleteIdentity',
              params: getUpdateBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'handleBuyerDone',
                params: getUpdateBuyerParams,
              },
            ],
            target: 'ready.completeIdentity',
          },
          {
            guard: {
              type: 'hasBuyerResult',
              params: getUpdateBuyerParams,
            },
            actions: [
              {
                type: 'assignBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'identifyBuyer',
                params: getUpdateBuyerParams,
              },
              {
                type: 'handleBuyerDone',
                params: getUpdateBuyerParams,
              },
            ],
            target: 'ready.incomplete',
          },
          {
            target: 'ready.initial',
            actions: [
              {
                type: 'handleBuyerDone',
                params: getCreateBuyerParams,
              },
            ],
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'handleServiceError',
                params: getServiceErrorParams,
              },
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'ready.initial',
          },
        ],
      },
    },
    preparingCartBuyer: {
      invoke: {
        src: 'prepareCartBuyer',
        input: getPrepareCartBuyerInput,
        onDone: [
          {
            guard: {
              type: 'isCartComplete',
              params: getPreparingCartParams,
            },
            actions: [
              {
                type: 'assignCartBuyer',
                params: getPreparingCartParams,
              },
            ],
            target: 'ready.cartRetrieved',
          },
          {
            target: 'errors.unknown',
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'errors.unknown',
          },
        ],
      },
    },
    evaluateBuyerError: {
      invoke: {
        src: 'getErrorReasonFromResponse',
        input: getEvaluateBuyerErrorInput,
        onDone: [
          {
            guard: {
              type: 'isVerifiedEmailError',
              params: getEvaluateBuyerErrorParams,
            },
            target: 'errors.verifiedEmail',
          },
          {
            target: 'errors.unknown',
          },
        ],
        onError: [
          {
            target: 'errors.unknown',
          },
        ],
      },
    },
    errors: {
      initial: 'unknown',
      states: {
        verifiedEmail: {},
        unknown: {},
      },
    },
  },
});
