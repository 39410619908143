import { fromPromise, setup } from 'xstate';
import {
  Application,
  Offer,
  PaymentAgreementDocument,
} from '@ads-bread/shared/bread/codecs';
import { ActorErrorParams } from '../../../apply/lib/types';
import {
  ApplicationMachineEvents,
  SendApplicationCheckoutParams,
  SendApplicationPrepareCheckoutParams,
  SendAssignSelectedOfferParams,
  SendEvaluateIDVerificationImagesParams,
  SendFetchApplicationParams,
  SendReCreateApplicationParams,
} from './types/events';
import {
  assignApplication,
  assignApplicationWithAppendedStatusCodes,
  assignIDVerificationBackImageData,
  assignIDVerificationFrontImageData,
  assignIDVerificationIsIDVerified,
  assignPaymentAgreementDocument,
  assignSelectedOffer,
  resetContext,
} from './assigns';
import {
  ApplicationCheckoutResult,
  ApplicationPrepareCheckoutResult,
  CreateApplicationResult,
  EvaluateApplicationErrorResult,
  EvaluateIDVerificationImagesResult,
  FetchApplicationResult,
  FetchLatestApplicationResult,
  PrepareInStoreApplicationResult,
  PrepareVirtualCardIssuanceResult,
  ReCreateApplicationResult,
  ReCreateInStoreContingentApplicationResult,
} from './types/actors';
import {
  getApplicationCheckoutInput,
  getApplicationPrepareCheckoutInput,
  getCreateApplicationInput,
  getEvaluateApplicationErrorInput,
  getEvaluateIDVerificationImagesInput,
  getFetchApplicationInput,
  getFetchLatestApplicationInput,
  getPrepareInStoreApplicationInput,
  getPrepareVirtualCadIssuanceInput,
  getReCreateApplicationInput,
  getReCreateInStoreContingentApplicationInput,
} from './inputs';
import {
  getApplicationCheckoutParams,
  getApplicationPrepareCheckoutParams,
  getAssignIDVerificationBackImageDataParams,
  getAssignIDVerificationFrontImageDataParams,
  getAssignPaymentAgreementDocument,
  getAssignSelectedOfferParams,
  getCreateApplicationParams,
  getEvaluateApplicationErrorParams,
  getEvaluateIDVerificationImagesParams,
  getFetchApplicationParams,
  getFetchLatestApplicationParams,
  getPrepareInStoreApplicationParams,
  getReCreateApplicationParams,
  getServiceErrorParams,
} from './actions';

export type IDVerification = {
  frontImageData: Blob | null;
  backImageData: Blob | null;
  isIDVerified: boolean;
};

export interface ApplicationContext {
  application: Application | null;
  selectedOffer: Offer | null;
  paymentAgreementDocument: PaymentAgreementDocument | null;
  idVerification: IDVerification;
}

export const applicationMachine = setup({
  types: {
    context: {} as ApplicationContext,
    events: {} as ApplicationMachineEvents,
    input: {} as ApplicationContext,
  },
  guards: {
    isInstallmentOfferSelected: (_, __: SendAssignSelectedOfferParams) => {
      throw new Error('Not implemented');
    },
    isSplitPayOfferSelected: (_, __: SendAssignSelectedOfferParams) => {
      throw new Error('Not implemented');
    },
    isUnexpiredInStoreApplication: (_, __: FetchLatestApplicationResult) => {
      throw new Error('Not implemented');
    },
    isIneligibleAddress: (_, __: FetchLatestApplicationResult) => {
      throw new Error('Not implemented');
    },
    needsFullIIN: (
      _,
      __:
        | FetchLatestApplicationResult
        | CreateApplicationResult
        | ReCreateApplicationResult
    ) => {
      throw new Error('Not implemented');
    },
    isValidApplicationAndMatchesPreviouslyApprovedOrder: (
      _,
      __: FetchLatestApplicationResult
    ) => {
      throw new Error('Not implemented');
    },
    isBuyerReadyAndComplete: (): boolean => {
      throw new Error('Not implemented');
    },
    isInvalidApplication: (_, __: FetchLatestApplicationResult) => {
      throw new Error('Not implemented');
    },
    isCompleteMismatchedBuyer: () => {
      throw new Error('Not implemented');
    },
    isApproved: (
      _,
      __:
        | CreateApplicationResult
        | ReCreateApplicationResult
        | ReCreateInStoreContingentApplicationResult
    ) => {
      throw new Error('Not implemented');
    },
    isApprovedContingentInStore: (_, __: CreateApplicationResult) => {
      throw new Error('Not implemented');
    },
    needsIDVerification: (
      _,
      __:
        | CreateApplicationResult
        | ReCreateApplicationResult
        | ApplicationCheckoutResult
        | ApplicationPrepareCheckoutResult
    ) => {
      throw new Error('Not implemented');
    },
    needsIDVerificationAlloy: (
      _,
      __:
        | CreateApplicationResult
        | ReCreateApplicationResult
        | ApplicationCheckoutResult
        | ApplicationPrepareCheckoutResult
    ) => {
      throw new Error('Not implemented');
    },
    isCheckoutCompleted: (_, __: ApplicationCheckoutResult) => {
      throw new Error('Not implemented');
    },
    isDownPaymentAuthDeclinedError: (_, __: ApplicationCheckoutResult) => {
      throw new Error('Not implemented');
    },
    isCheckoutPrepared: (_, __: ApplicationPrepareCheckoutResult) => {
      throw new Error('Not implemented');
    },
    isErrorResult: (_, __: ApplicationPrepareCheckoutResult) => {
      throw new Error('Not implemented');
    },
    isVirtualCardApplication: (): boolean => {
      throw new Error('Not implemented');
    },
    isIDVerificationSuccess: (_, __: EvaluateIDVerificationImagesResult) => {
      throw new Error('Not implemented');
    },
    isIDVerificationFormError: (_, __: EvaluateIDVerificationImagesResult) => {
      throw new Error('Not implemented');
    },
    isIDVerificationNeedsActionWarning: (
      _,
      __: EvaluateIDVerificationImagesResult
    ) => {
      throw new Error('Not implemented');
    },
    isIDVerificationFailure: (_, __: EvaluateIDVerificationImagesResult) => {
      throw new Error('Not implemented');
    },
    isIDVerificationRetryError: (_, __: EvaluateIDVerificationImagesResult) => {
      throw new Error('Not implemented');
    },
    isIDVerificationErrorMaxAttemptsExceeded: (
      _,
      __: EvaluateIDVerificationImagesResult
    ) => {
      throw new Error('Not implemented');
    },
    isPoBoxAddressIneligible: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isBuyerHashFailed: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isCreditFreeze: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isKycDenial: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isPreviousDenialIneligible: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isMaxCardIneligible: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isOutstandingLoansIneligible: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isAgeIneligible: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isAgeAlabamaMilitary: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isBuyerStatusIneligible: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isBuyerSkippedInstallments: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isLocationIneligible: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isNeedsActionWarning: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isFraudDenial: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isCreditDenial: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isSanctionsOFACDenial: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isSanctionsDenial: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isFraudAlertDenial: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
    isCapacityRecheck: (_, __: EvaluateApplicationErrorResult) => {
      throw new Error('Not implemented');
    },
  },
  actions: {
    assignApplication,
    assignApplicationWithAppendedStatusCodes,
    assignPaymentAgreementDocument,
    assignSelectedOffer,
    assignIDVerificationFrontImageData,
    assignIDVerificationBackImageData,
    assignIDVerificationIsIDVerified,
    resetContext,
    handleApplicationDone: (
      _,
      __:
        | FetchApplicationResult
        | ReCreateApplicationResult
        | ApplicationCheckoutResult
        | ApplicationPrepareCheckoutResult
    ) => {
      throw new Error('Not implemented');
    },
    handleIDVerificationDone: (_, __: EvaluateIDVerificationImagesResult) => {
      throw new Error('Not implemented');
    },
    handleApplicationServiceError: (_, __: ActorErrorParams): void => {
      throw new Error('Not implemented');
    },
    handleApplicationAnalytics: (
      _,
      __:
        | FetchLatestApplicationResult
        | CreateApplicationResult
        | ApplicationCheckoutResult
        | ApplicationPrepareCheckoutResult
    ) => {
      throw new Error('Not implemented');
    },
    handleSelectedOfferAnalytics: (_, __: SendAssignSelectedOfferParams) => {
      throw new Error('Not implemented');
    },
    handleOnApproved: (_, __: CreateApplicationResult) => {
      throw new Error('Not implemented');
    },
    handleOnCheckout: (_, __: ApplicationCheckoutResult) => {
      throw new Error('Not implemented');
    },
    logError: (_, __: ActorErrorParams): void => {
      throw new Error('Not implemented');
    },
  },
  actors: {
    fetchApplication: fromPromise<
      FetchApplicationResult,
      SendFetchApplicationParams
    >(() => {
      throw new Error('Not implemented');
    }),
    fetchLatestApplication: fromPromise<FetchLatestApplicationResult>(() => {
      throw new Error('Not implemented');
    }),
    createApplication: fromPromise<CreateApplicationResult>(() => {
      throw new Error('Not implemented');
    }),
    reCreateApplication: fromPromise<
      ReCreateApplicationResult,
      SendReCreateApplicationParams
    >(() => {
      throw new Error('Not implemented');
    }),
    applicationCheckout: fromPromise<
      ApplicationCheckoutResult,
      SendApplicationCheckoutParams
    >(() => {
      throw new Error('Not implemented');
    }),
    applicationPrepareCheckout: fromPromise<
      ApplicationPrepareCheckoutResult,
      SendApplicationPrepareCheckoutParams
    >(() => {
      throw new Error('Not implemented');
    }),
    prepareInStoreApplication: fromPromise<
      PrepareInStoreApplicationResult,
      ApplicationContext
    >(() => {
      throw new Error('Not implemented');
    }),
    prepareVirtualCardIssuance: fromPromise<
      PrepareVirtualCardIssuanceResult,
      ApplicationContext
    >(() => {
      throw new Error('Not implemented');
    }),
    reCreateInStoreContingentApplication: fromPromise<
      ReCreateInStoreContingentApplicationResult,
      CreateApplicationResult
    >(() => {
      throw new Error('Not implemented');
    }),
    evaluateIDVerificationImages: fromPromise<
      EvaluateIDVerificationImagesResult,
      SendEvaluateIDVerificationImagesParams
    >(() => {
      throw new Error('Not implemented');
    }),
    getErrorStatusCodeFromApplication: fromPromise<
      EvaluateApplicationErrorResult,
      unknown
    >(() => {
      throw new Error('Not implemented');
    }),
  },
}).createMachine({
  id: 'application',
  initial: 'initial',
  context: ({ input }) => {
    return {
      application: input.application || null,
      selectedOffer: input.selectedOffer || null,
      paymentAgreementDocument: input.paymentAgreementDocument || null,
      idVerification: {
        frontImageData: input.idVerification.frontImageData || null,
        backImageData: input.idVerification.backImageData || null,
        isIDVerified: input.idVerification.isIDVerified || false,
      },
    };
  },
  on: {
    SEND_RESET_APPLICATION_STATE: {
      actions: [
        {
          type: 'resetContext',
        },
      ],
      target: '.initial',
    },
  },
  states: {
    initial: {
      on: {
        SEND_FETCH_LATEST_APPLICATION: {
          target: 'fetchingLatestApplication',
        },
        SEND_APPLICATION_READY: {
          target: 'ready',
        },
      },
    },
    ready: {
      on: {
        SEND_FETCH_APPLICATION: {
          target: 'fetchingApplication',
        },
        SEND_FETCH_LATEST_APPLICATION: {
          target: 'fetchingLatestApplication',
        },
        SEND_CREATE_APPLICATION: {
          target: 'creatingApplication',
        },
        SEND_RECREATE_APPLICATION: {
          target: 'reCreatingApplication',
        },
        SEND_APPLICATION_CHECKOUT: {
          target: 'creatingApplicationCheckout',
        },
        SEND_APPLICATION_PREPARE_CHECKOUT: {
          target: 'creatingApplicationPrepareCheckout',
        },
        SEND_ASSIGN_PAYMENT_AGREEMENT_DOCUMENT: {
          actions: [
            {
              type: 'assignPaymentAgreementDocument',
              params: getAssignPaymentAgreementDocument,
            },
          ],
        },
      },
      initial: 'initial',
      states: {
        initial: {},
        approved: {
          initial: 'initial',
          on: {
            SEND_ASSIGN_SELECTED_OFFER: [
              {
                guard: {
                  type: 'isInstallmentOfferSelected',
                  params: getAssignSelectedOfferParams,
                },
                actions: [
                  {
                    type: 'assignSelectedOffer',
                    params: getAssignSelectedOfferParams,
                  },
                  {
                    type: 'handleSelectedOfferAnalytics',
                    params: getAssignSelectedOfferParams,
                  },
                ],
                target: '.selected.installment',
              },
              {
                guard: {
                  type: 'isSplitPayOfferSelected',
                  params: getAssignSelectedOfferParams,
                },
                actions: [
                  {
                    type: 'assignSelectedOffer',
                    params: getAssignSelectedOfferParams,
                  },
                  {
                    type: 'handleSelectedOfferAnalytics',
                    params: getAssignSelectedOfferParams,
                  },
                ],
                target: '.selected.splitPay',
              },
              {
                target: '.selected.initial',
              },
            ],
          },
          states: {
            initial: {},
            idVerified: {},
            // In Store returning buyer with application states
            inStore: {
              initial: 'initial',
              states: {
                initial: {},
                prepared: {},
                virtualCard: {},
              },
            },
            // Determined selected offer
            selected: {
              initial: 'initial',
              states: {
                initial: {},
                splitPay: {},
                installment: {},
              },
            },
          },
        },
        needsFullIIN: {
          on: {
            // Override top level event. We want to manually re-create this application
            SEND_FETCH_LATEST_APPLICATION: {},
          },
        },
        needsIDVerification: {
          on: {
            SEND_ASSIGN_ID_VERIFICATION_FRONT_IMAGE_DATA: {
              actions: [
                {
                  type: 'assignIDVerificationFrontImageData',
                  params: getAssignIDVerificationFrontImageDataParams,
                },
              ],
              target: '.assigningImage',
            },
            SEND_ASSIGN_ID_VERIFICATION_BACK_IMAGE_DATA: {
              actions: [
                {
                  type: 'assignIDVerificationBackImageData',
                  params: getAssignIDVerificationBackImageDataParams,
                },
              ],
              target: '.assigningImage',
            },
            SEND_EVALUATE_ID_VERIFICATION_IMAGES: {
              target: '.evaluatingIDVerificationImages',
            },
          },
          initial: 'initial',
          states: {
            initial: {},
            assigningImage: {},
            formError: {},
            retryError: {},
            evaluatingIDVerificationImages: {
              invoke: {
                src: 'evaluateIDVerificationImages',
                input: getEvaluateIDVerificationImagesInput,
                onDone: [
                  {
                    guard: {
                      type: 'isIDVerificationSuccess',
                      params: getEvaluateIDVerificationImagesParams,
                    },
                    actions: [
                      {
                        type: 'assignIDVerificationIsIDVerified',
                        params: getEvaluateIDVerificationImagesParams,
                      },
                      {
                        type: 'handleIDVerificationDone',
                        params: getEvaluateIDVerificationImagesParams,
                      },
                    ],
                    target: '#application.ready.approved.idVerified',
                  },
                  {
                    guard: {
                      type: 'isIDVerificationFormError',
                      params: getEvaluateIDVerificationImagesParams,
                    },
                    actions: [
                      {
                        type: 'handleIDVerificationDone',
                        params: getEvaluateIDVerificationImagesParams,
                      },
                    ],
                    target: 'formError',
                  },
                  {
                    guard: {
                      type: 'isIDVerificationNeedsActionWarning',
                      params: getEvaluateIDVerificationImagesParams,
                    },
                    actions: [
                      {
                        type: 'handleIDVerificationDone',
                        params: getEvaluateIDVerificationImagesParams,
                      },
                    ],
                    target:
                      '#application.errors.idVerificationNeedsActionWarning',
                  },
                  {
                    guard: {
                      type: 'isIDVerificationFailure',
                      params: getEvaluateIDVerificationImagesParams,
                    },
                    actions: [
                      {
                        type: 'handleIDVerificationDone',
                        params: getEvaluateIDVerificationImagesParams,
                      },
                    ],
                    target: '#application.errors.idVerificationFailure',
                  },
                  {
                    guard: {
                      type: 'isIDVerificationRetryError',
                      params: getEvaluateIDVerificationImagesParams,
                    },
                    actions: [
                      {
                        type: 'handleIDVerificationDone',
                        params: getEvaluateIDVerificationImagesParams,
                      },
                    ],
                    target: 'retryError',
                  },
                  {
                    guard: {
                      type: 'isIDVerificationErrorMaxAttemptsExceeded',
                      params: getEvaluateIDVerificationImagesParams,
                    },
                    actions: [
                      {
                        type: 'handleIDVerificationDone',
                        params: getEvaluateIDVerificationImagesParams,
                      },
                    ],
                    target:
                      '#application.errors.idVerificationMaxAttemptsExceeded',
                  },
                  {
                    actions: [
                      {
                        type: 'handleIDVerificationDone',
                        params: getEvaluateIDVerificationImagesParams,
                      },
                    ],
                    target: '#application.errors.unknown',
                  },
                ],
                onError: [
                  {
                    actions: [
                      {
                        type: 'handleApplicationServiceError',
                        params: getServiceErrorParams,
                      },
                      {
                        type: 'logError',
                        params: getServiceErrorParams,
                      },
                    ],
                    target: '#application.errors.unknown',
                  },
                ],
              },
            },
          },
        },
        needsIDVerificationAlloy: {
          on: {
            SEND_ID_VERIFICATION_ALLOY_SUCCESS: {
              target: '#application.ready.approved.idVerified',
            },
            SEND_ID_VERIFICATION_ALLOY_ERROR: {
              target: '#application.errors.unknown',
            },
          },
          initial: 'initial',
          states: {
            initial: {},
          },
        },
        // No route events are associated with this state. Use when you want a wait state
        recoverableError: {},
        invalid: {},
        checkoutCompleted: {},
        checkoutPrepared: {},
      },
    },
    fetchingApplication: {
      invoke: {
        src: 'fetchApplication',
        id: 'fetchApplication',
        input: getFetchApplicationInput,
        onDone: [
          {
            actions: [
              {
                type: 'assignApplication',
                params: getFetchApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getFetchApplicationParams,
              },
              {
                type: 'handleApplicationDone',
                params: getFetchApplicationParams,
              },
            ],
            target: 'ready.approved',
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'handleApplicationServiceError',
                params: getServiceErrorParams,
              },
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'ready.initial',
          },
        ],
      },
    },
    fetchingLatestApplication: {
      invoke: {
        src: 'fetchLatestApplication',
        id: 'fetchLatestApplication',
        input: getFetchLatestApplicationInput,
        onDone: [
          {
            guard: {
              type: 'isUnexpiredInStoreApplication',
              params: getFetchLatestApplicationParams,
            },
            actions: [
              {
                type: 'assignApplication',
                params: getFetchLatestApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getFetchLatestApplicationParams,
              },
            ],
            target: 'preparingInStoreApplication',
          },
          {
            guard: {
              type: 'isIneligibleAddress',
              params: getFetchLatestApplicationParams,
            },
            target: 'errors.poBoxAddressIneligible',
          },
          {
            guard: {
              type: 'needsFullIIN',
              params: getFetchLatestApplicationParams,
            },
            target: 'ready.needsFullIIN',
          },
          {
            guard: {
              type: 'isValidApplicationAndMatchesPreviouslyApprovedOrder',
              params: getFetchLatestApplicationParams,
            },
            actions: [
              {
                type: 'assignApplication',
                params: getFetchLatestApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getFetchLatestApplicationParams,
              },
            ],
            target: 'ready.approved',
          },
          {
            guard: {
              type: 'isCompleteMismatchedBuyer',
              params: getFetchLatestApplicationParams,
            },
            target: 'creatingApplication',
          },
          {
            guard: {
              type: 'isInvalidApplication',
              params: getFetchLatestApplicationParams,
            },
            target: 'ready.invalid',
          },
          {
            guard: {
              type: 'isBuyerReadyAndComplete',
              params: getFetchLatestApplicationParams,
            },
            target: 'creatingApplication',
          },
          {
            target: 'ready.invalid',
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'ready.initial',
          },
        ],
      },
    },
    preparingInStoreApplication: {
      invoke: {
        src: 'prepareInStoreApplication',
        input: getPrepareInStoreApplicationInput,
        onDone: [
          {
            guard: {
              type: 'isVirtualCardApplication',
              params: getPrepareInStoreApplicationParams,
            },
            actions: [
              {
                type: 'assignApplication',
                params: getPrepareInStoreApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getPrepareInStoreApplicationParams,
              },
            ],
            target: 'preparingVirtualCardApplication',
          },
          {
            actions: [
              {
                type: 'assignApplication',
                params: getPrepareInStoreApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getPrepareInStoreApplicationParams,
              },
            ],
            target: 'ready.approved.inStore.prepared',
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'errors.unknown',
          },
        ],
      },
    },
    preparingVirtualCardApplication: {
      invoke: {
        src: 'prepareVirtualCardIssuance',
        input: getPrepareVirtualCadIssuanceInput,
        onDone: [
          {
            target: 'ready.approved.inStore.virtualCard',
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'errors.unknown',
          },
        ],
      },
    },
    creatingApplication: {
      invoke: {
        src: 'createApplication',
        input: getCreateApplicationInput,
        onDone: [
          {
            guard: {
              type: 'isApprovedContingentInStore',
              params: getCreateApplicationParams,
            },
            target: 'reCreatingInStoreContingentApplication',
          },
          {
            guard: {
              type: 'isApproved',
              params: getCreateApplicationParams,
            },
            actions: [
              {
                type: 'assignApplication',
                params: getCreateApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getCreateApplicationParams,
              },
              {
                type: 'handleOnApproved',
                params: getCreateApplicationParams,
              },
            ],
            target: 'ready.approved',
          },
          {
            guard: {
              type: 'needsFullIIN',
              params: getCreateApplicationParams,
            },
            actions: [
              {
                type: 'assignApplication',
                params: getCreateApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getCreateApplicationParams,
              },
            ],
            target: 'ready.needsFullIIN',
          },
          {
            guard: {
              type: 'needsIDVerification',
              params: getCreateApplicationParams,
            },
            actions: [
              {
                type: 'assignApplicationWithAppendedStatusCodes',
                params: getCreateApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getCreateApplicationParams,
              },
            ],
            target: 'ready.needsIDVerification',
          },
          {
            guard: {
              type: 'needsIDVerificationAlloy',
              params: getCreateApplicationParams,
            },
            actions: [
              {
                type: 'assignApplicationWithAppendedStatusCodes',
                params: getCreateApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getCreateApplicationParams,
              },
            ],
            target: 'ready.needsIDVerificationAlloy',
          },
          {
            target: 'evaluateApplicationError',
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'errors.unknown',
          },
        ],
      },
    },
    reCreatingInStoreContingentApplication: {
      invoke: {
        src: 'reCreateInStoreContingentApplication',
        input: getReCreateInStoreContingentApplicationInput,
        onDone: [
          {
            guard: {
              type: 'isApproved',
              params: getCreateApplicationParams,
            },
            actions: [
              {
                type: 'assignApplication',
                params: getCreateApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getCreateApplicationParams,
              },
              {
                type: 'handleOnApproved',
                params: getCreateApplicationParams,
              },
            ],
            target: 'ready.approved',
          },
          {
            guard: {
              type: 'needsFullIIN',
              params: getCreateApplicationParams,
            },
            actions: [
              {
                type: 'assignApplication',
                params: getCreateApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getCreateApplicationParams,
              },
            ],
            target: 'ready.needsFullIIN',
          },
          {
            guard: {
              type: 'needsIDVerification',
              params: getCreateApplicationParams,
            },
            actions: [
              {
                type: 'assignApplicationWithAppendedStatusCodes',
                params: getCreateApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getCreateApplicationParams,
              },
            ],
            target: 'ready.needsIDVerification',
          },
          {
            guard: {
              type: 'needsIDVerificationAlloy',
              params: getCreateApplicationParams,
            },
            actions: [
              {
                type: 'assignApplicationWithAppendedStatusCodes',
                params: getCreateApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getCreateApplicationParams,
              },
            ],
            target: 'ready.needsIDVerificationAlloy',
          },
          {
            target: 'evaluateApplicationError',
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'errors.unknown',
          },
        ],
      },
    },
    reCreatingApplication: {
      invoke: {
        src: 'reCreateApplication',
        input: getReCreateApplicationInput,
        onDone: [
          {
            guard: {
              type: 'isApproved',
              params: getReCreateApplicationParams,
            },
            actions: [
              {
                type: 'assignApplication',
                params: getReCreateApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getReCreateApplicationParams,
              },
              {
                type: 'handleOnApproved',
                params: getReCreateApplicationParams,
              },
              {
                type: 'handleApplicationDone',
                params: getReCreateApplicationParams,
              },
            ],
            target: 'ready.approved',
          },
          {
            guard: {
              type: 'needsFullIIN',
              params: getCreateApplicationParams,
            },
            actions: [
              {
                type: 'assignApplication',
                params: getReCreateApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getReCreateApplicationParams,
              },
              {
                type: 'handleApplicationDone',
                params: getReCreateApplicationParams,
              },
            ],
            target: 'ready.needsFullIIN',
          },
          {
            guard: {
              type: 'needsIDVerification',
              params: getReCreateApplicationParams,
            },
            actions: [
              {
                type: 'assignApplicationWithAppendedStatusCodes',
                params: getReCreateApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getReCreateApplicationParams,
              },
              {
                type: 'handleApplicationDone',
                params: getReCreateApplicationParams,
              },
            ],
            target: 'ready.needsIDVerification',
          },
          {
            guard: {
              type: 'needsIDVerificationAlloy',
              params: getReCreateApplicationParams,
            },
            actions: [
              {
                type: 'assignApplicationWithAppendedStatusCodes',
                params: getReCreateApplicationParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getReCreateApplicationParams,
              },
              {
                type: 'handleApplicationDone',
                params: getReCreateApplicationParams,
              },
            ],
            target: 'ready.needsIDVerificationAlloy',
          },
          {
            actions: [
              {
                type: 'handleApplicationDone',
                params: getReCreateApplicationParams,
              },
            ],
            target: 'evaluateApplicationError',
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'handleApplicationServiceError',
                params: getServiceErrorParams,
              },
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'errors.unknown',
          },
        ],
      },
    },
    creatingApplicationCheckout: {
      invoke: {
        src: 'applicationCheckout',
        input: getApplicationCheckoutInput,
        onDone: [
          {
            guard: {
              type: 'isCheckoutCompleted',
              params: getApplicationCheckoutParams,
            },
            actions: [
              {
                type: 'assignApplication',
                params: getApplicationCheckoutParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getApplicationCheckoutParams,
              },
              {
                type: 'handleOnCheckout',
                params: getApplicationCheckoutParams,
              },
              {
                type: 'handleApplicationDone',
                params: getApplicationCheckoutParams,
              },
            ],
            target: 'ready.checkoutCompleted',
          },
          {
            guard: {
              type: 'needsIDVerification',
              params: getApplicationCheckoutParams,
            },
            actions: [
              {
                type: 'assignApplicationWithAppendedStatusCodes',
                params: getApplicationCheckoutParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getApplicationCheckoutParams,
              },
              {
                type: 'handleApplicationDone',
                params: getApplicationCheckoutParams,
              },
            ],
            target: 'ready.needsIDVerification',
          },
          {
            guard: {
              type: 'needsIDVerificationAlloy',
              params: getApplicationCheckoutParams,
            },
            actions: [
              {
                type: 'assignApplicationWithAppendedStatusCodes',
                params: getApplicationCheckoutParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getApplicationCheckoutParams,
              },
              {
                type: 'handleApplicationDone',
                params: getApplicationCheckoutParams,
              },
            ],
            target: 'ready.needsIDVerificationAlloy',
          },
          {
            guard: {
              type: 'isDownPaymentAuthDeclinedError',
              params: getApplicationCheckoutParams,
            },
            actions: [
              {
                type: 'handleApplicationDone',
                params: getApplicationCheckoutParams,
              },
            ],
            target: 'ready.recoverableError',
          },
          {
            actions: [
              {
                type: 'handleApplicationDone',
                params: getApplicationCheckoutParams,
              },
            ],
            target: 'evaluateApplicationError',
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'handleApplicationServiceError',
                params: getServiceErrorParams,
              },
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'errors.unknown',
          },
        ],
      },
    },
    creatingApplicationPrepareCheckout: {
      invoke: {
        src: 'applicationPrepareCheckout',
        input: getApplicationPrepareCheckoutInput,
        onDone: [
          {
            guard: {
              type: 'isCheckoutPrepared',
              params: getApplicationPrepareCheckoutParams,
            },
            actions: [
              {
                type: 'assignApplication',
                params: getApplicationPrepareCheckoutParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getApplicationPrepareCheckoutParams,
              },
              {
                type: 'handleApplicationDone',
                params: getApplicationPrepareCheckoutParams,
              },
            ],
            target: 'ready.checkoutPrepared',
          },
          {
            guard: {
              type: 'needsIDVerification',
              params: getApplicationPrepareCheckoutParams,
            },
            actions: [
              {
                type: 'assignApplicationWithAppendedStatusCodes',
                params: getApplicationPrepareCheckoutParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getApplicationPrepareCheckoutParams,
              },
              {
                type: 'handleApplicationDone',
                params: getApplicationPrepareCheckoutParams,
              },
            ],
            target: 'ready.needsIDVerification',
          },
          {
            guard: {
              type: 'needsIDVerificationAlloy',
              params: getApplicationPrepareCheckoutParams,
            },
            actions: [
              {
                type: 'assignApplicationWithAppendedStatusCodes',
                params: getApplicationPrepareCheckoutParams,
              },
              {
                type: 'handleApplicationAnalytics',
                params: getApplicationPrepareCheckoutParams,
              },
              {
                type: 'handleApplicationDone',
                params: getApplicationPrepareCheckoutParams,
              },
            ],
            target: 'ready.needsIDVerificationAlloy',
          },
          {
            guard: {
              type: 'isErrorResult',
              params: getApplicationPrepareCheckoutParams,
            },
            actions: [
              {
                type: 'handleApplicationDone',
                params: getApplicationPrepareCheckoutParams,
              },
            ],
            target: 'ready.recoverableError',
          },
          {
            actions: [
              {
                type: 'handleApplicationDone',
                params: getApplicationPrepareCheckoutParams,
              },
            ],
            target: 'evaluateApplicationError',
          },
        ],
        onError: [
          {
            actions: [
              {
                type: 'handleApplicationServiceError',
                params: getServiceErrorParams,
              },
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
            target: 'errors.unknown',
          },
        ],
      },
    },
    evaluateApplicationError: {
      invoke: {
        src: 'getErrorStatusCodeFromApplication',
        input: getEvaluateApplicationErrorInput,
        onDone: [
          {
            guard: {
              type: 'isPoBoxAddressIneligible',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.poBoxAddressIneligible',
          },
          {
            guard: {
              type: 'isBuyerHashFailed',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.buyerHashFailed',
          },
          {
            guard: {
              type: 'isCreditFreeze',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.creditFreeze',
          },
          {
            guard: {
              type: 'isKycDenial',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.kycDenial',
          },
          {
            guard: {
              type: 'isPreviousDenialIneligible',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.previousDenialIneligible',
          },
          {
            guard: {
              type: 'isMaxCardIneligible',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.maxCardIneligible',
          },
          {
            guard: {
              type: 'isOutstandingLoansIneligible',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.outstandingLoansIneligible',
          },
          {
            guard: {
              type: 'isAgeIneligible',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.ageIneligible',
          },
          {
            guard: {
              type: 'isAgeAlabamaMilitary',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.ageAlabamaMilitary',
          },
          {
            guard: {
              type: 'isBuyerStatusIneligible',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.buyerStatusIneligible',
          },
          {
            guard: {
              type: 'isBuyerSkippedInstallments',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.buyerSkippedInstallments',
          },
          {
            guard: {
              type: 'isLocationIneligible',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.locationIneligible',
          },
          {
            guard: {
              type: 'isNeedsActionWarning',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.needsActionWarning',
          },
          {
            guard: {
              type: 'isFraudDenial',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.fraudDenial',
          },
          {
            guard: {
              type: 'isCreditDenial',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.creditDenial',
          },
          {
            guard: {
              type: 'isSanctionsOFACDenial',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.sanctionsOFACDenial',
          },
          {
            guard: {
              type: 'isSanctionsDenial',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.sanctionsDenial',
          },
          {
            guard: {
              type: 'isFraudAlertDenial',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.fraudAlertDenial',
          },
          {
            guard: {
              type: 'isCapacityRecheck',
              params: getEvaluateApplicationErrorParams,
            },
            target: 'errors.capacityRecheck',
          },
          {
            target: 'errors.unknown',
          },
        ],
        onError: [
          {
            target: 'errors.unknown',
          },
        ],
      },
    },
    errors: {
      initial: 'unknown',
      states: {
        poBoxAddressIneligible: {
          on: {
            SEND_CREATE_APPLICATION: {
              target: '#application.creatingApplication',
            },
          },
        },
        buyerHashFailed: {
          on: {
            SEND_RECREATE_APPLICATION: {
              target: '#application.reCreatingApplication',
            },
          },
        },
        creditFreeze: {},
        kycDenial: {},
        previousDenialIneligible: {},
        maxCardIneligible: {},
        outstandingLoansIneligible: {},
        ageIneligible: {},
        ageAlabamaMilitary: {},
        buyerStatusIneligible: {},
        buyerSkippedInstallments: {},
        locationIneligible: {},
        needsActionWarning: {},
        fraudDenial: {},
        creditDenial: {},
        sanctionsOFACDenial: {},
        sanctionsDenial: {},
        fraudAlertDenial: {},
        idVerificationNeedsActionWarning: {},
        idVerificationFailure: {},
        idVerificationMaxAttemptsExceeded: {},
        capacityRecheck: {
          on: {
            SEND_RECREATE_APPLICATION: {
              target: '#application.reCreatingApplication',
            },
          },
        },
        unknown: {},
      },
    },
  },
});
